import { Breadcrumb } from 'antd';
import Container from '../../components/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import AchievementForm from 'src/sections/achievement/AchievementForm';
import { useAchievementQuery } from 'src/graphql/queries/achievement.graphql';
import { useState } from 'react';
import { AchievementCustom } from 'src/graphql/type.interface';

export default () => {
  const navigate = useNavigate();
  const _id = useParams()._id as string;
  const [achievement, setAchievement] = useState<AchievementCustom>();

  useAchievementQuery({
    variables: { _id },
    onCompleted(data) {
      setAchievement(data.achievement);
    },
  });
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.achievement.list)}>
                Thành tựu
              </p>
            ),
          },
          {
            title: 'Sửa',
          },
        ]}
      />
      <Container title="Sửa thành tựu">
        <AchievementForm achievement={achievement} />
      </Container>
    </>
  );
};
