import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAchievementMutationVariables = Types.Exact<{
  input: Types.CreateAchievementDto;
}>;

export type CreateAchievementMutationResponse = { __typename?: 'Mutation' } & {
  createAchievement: { __typename?: 'Achievement' } & Pick<
    Types.Achievement,
    | '_id'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'isDeleted'
    | 'name'
    | 'performs'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  > & {
      completionQuantity?: Types.Maybe<
        { __typename?: 'AchievementCompletionQuantity' } & Pick<
          Types.AchievementCompletionQuantity,
          'quantity' | 'type'
        >
      >;
      reward: { __typename?: 'AchievementReward' } & Pick<Types.AchievementReward, 'type' | 'value'>;
    };
};

export const CreateAchievementDocument = gql`
  mutation createAchievement($input: CreateAchievementDto!) {
    createAchievement(input: $input) {
      _id
      completionQuantity {
        quantity
        type
      }
      createdAt
      createdBy
      description
      isDeleted
      name
      performs
      reward {
        type
        value
      }
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useCreateAchievementMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAchievementMutationResponse, CreateAchievementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAchievementMutationResponse, CreateAchievementMutationVariables>(
    CreateAchievementDocument,
    options,
  );
}
export type CreateAchievementMutationHookResult = ReturnType<typeof useCreateAchievementMutation>;
export type CreateAchievementMutationResult = Apollo.MutationResult<CreateAchievementMutationResponse>;
export type CreateAchievementMutationOptions = Apollo.BaseMutationOptions<
  CreateAchievementMutationResponse,
  CreateAchievementMutationVariables
>;
