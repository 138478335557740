import { object, string } from 'yup';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button, Col, Form, Input, Row, Select } from 'antd';

import {
  NO_SPACE,
  FIELD_REQUIRED,
  CREATE_ERROR_MESSAGE,
  CREATE_SUCCESS_MESSAGE,
  NO_EMOJI,
} from 'src/constants/message';
import { PATH } from 'src/configs/path';
import { ContentProviderG1 } from 'src/graphql/type.interface';
import { regex, multiSpaceRegex } from 'src/constants/constants';
import ServiceRepository, { CreateSpecialCodeCP } from 'src/apis/service/package';
import { useAllContentProvidersQuery } from 'src/graphql/queries/allContentProviders.graphql';

type Props = { currentCode?: any };

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      command: string().required(FIELD_REQUIRED),
      description: string()
        .required(FIELD_REQUIRED)
        .test({
          test: (value) => {
            if (value) {
              return regex.test(value);
            }
            if (value === '') {
              return true;
            }
            return true;
          },
          message: NO_EMOJI,
        })
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE }),
      contentProviderId: string().required(FIELD_REQUIRED),
    }).validateAt(field, { [field]: value });
  },
};

export default ({ currentCode }: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [providers, setProviders] = useState<ContentProviderG1[]>([]);

  useEffect(() => {
    if (currentCode) {
      const { command, contentProviderId, description } = currentCode;

      form.setFieldsValue({ command, contentProviderId, description });
    }
  }, [currentCode]);

  useAllContentProvidersQuery({
    variables: { query: { limit: 20, page: 1 } },
    onCompleted(data) {
      setProviders(data.allContentProviders.items);
    },
  });

  const { mutateAsync: mutateCreateSpecialCodeCP } = useMutation(
    (payload: any) => ServiceRepository.createSpecialCodeCP(payload),
    {
      onError() {
        enqueueSnackbar(CREATE_ERROR_MESSAGE, { variant: 'error' });
      },
      onSuccess({ success, message }) {
        if (success) {
          enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
          navigate(PATH.package.specialCodeCP.list);
        } else {
          enqueueSnackbar(message, { variant: 'error' });
        }
      },
    },
  );

  const { mutateAsync: mutateUpdateSpecialCodeCP } = useMutation(
    (payload: any) => ServiceRepository.updateSpecialCodeCP(payload),
    {
      onError() {
        enqueueSnackbar(CREATE_ERROR_MESSAGE, { variant: 'error' });
      },
      onSuccess({ success, message }) {
        if (success) {
          enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
          navigate(PATH.package.specialCodeCP.list);
        } else {
          enqueueSnackbar(message, { variant: 'error' });
        }
      },
    },
  );

  const onFinish = async (form: CreateSpecialCodeCP) => {
    const { command, contentProviderId, description } = form;
    if (currentCode) {
      mutateUpdateSpecialCodeCP({ id: Number(currentCode.id), command, contentProviderId, description });
    } else {
      mutateCreateSpecialCodeCP({ command, contentProviderId, description });
    }
  };

  return (
    <Row>
      <Col offset={3} span={18}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label={
              <span>
                Cú pháp <span style={{ color: 'red' }}>*</span>
              </span>
            }
            required={false}
            labelCol={{ span: 24 }}
            name="command"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Đối tác <span style={{ color: 'red' }}>*</span>
              </span>
            }
            required={false}
            labelCol={{ span: 24 }}
            name="contentProviderId"
            rules={[yupSync]}
          >
            <Select showSearch optionFilterProp="children" placeholder="Chọn đối tác">
              {providers.map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Mô tả <span style={{ color: 'red' }}>*</span>
              </span>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="description"
            rules={[yupSync]}
          >
            <Input.TextArea />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
