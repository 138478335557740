import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Card, Col, DatePicker, Form, Row, Select, Space, Table } from 'antd';
import Container from 'src/components/Container';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import { ContentProviderG1, PromotionCampaign } from 'src/graphql/type.interface';
import dayjs from 'dayjs';
import { FORMAT_PARAM_DATE, FORMAT_TIME_TO_MINUTES } from 'src/constants/common';
import { RangeValue } from 'rc-picker/lib/interface';
import { usePromotionCampaignsQuery } from 'src/graphql/queries/promotionCampaigns.graphql';
import { useAllContentProvidersQuery } from 'src/graphql/queries/allContentProviders.graphql';
import { useQuery } from '@tanstack/react-query';
import PromotionRespon from 'src/apis/service/promotionArtifactCodes';
import { TYPE_REWARD_PROMOTION } from 'src/constants/promotion';
import { saveAs } from 'file-saver';
import { useDetailStore } from 'src/stores';

export default () => {
  const { userDetail } = useDetailStore();
  const navigate = useNavigate();
  const currentDate = dayjs();
  const [form] = Form.useForm();
  const previousDate = currentDate.subtract(1, 'day').format('YYYY-MM-DD');
  const eightDaysAgo = currentDate.subtract(8, 'day').format('YYYY-MM-DD');
  const [tableData, setTableData] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({
    limit: 10,
    page: 1,
    startDay: eightDaysAgo,
    endDay: previousDate,
    contentProviderId: userDetail?.contentProviderId ?? undefined,
  });
  const [totalItems, setTotalItems] = useState<number>(0);
  const [promotionData, setPromotionData] = useState<PromotionCampaign[]>([]);
  const [cpData, setCPData] = useState<ContentProviderG1[]>([]);
  const [isloading, setIsloading] = useState<boolean>(true);

  const { refetch } = useQuery(['getPromotionEligible'], () => PromotionRespon.getPromotionEligible(filter), {
    retry: 0,
    refetchOnWindowFocus: false,
    onError() {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    },
    onSuccess: ({ data, total }) => {
      setTableData(data);
      setTotalItems(total);
      setIsloading(false);
    },
  });

  const handleExportExcel = async () => {
    const filename = `${cpData.find((item) => item._id === filter?.contentProviderId)?.name}-${
      promotionData.find((item) => item._id === filter?.campaignId)?.nameDispatch
    }-${dayjs().format('YYYYMMDDHHmmss')}`;

    try {
      const response = await PromotionRespon.exportExcelPromotionEligible({
        ...filter,
        page: undefined,
        limit: undefined,
      });

      if (response && response.size > 100) {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });

        saveAs(blob, filename);
      } else {
        enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  usePromotionCampaignsQuery({
    variables: { query: { limit: 10000, page: 1, contentProviderId: userDetail?.contentProviderId } },
    onCompleted(data) {
      setPromotionData(data.promotionCampaigns.items);
    },
  });

  useAllContentProvidersQuery({
    variables: { query: { limit: 10000, page: 1 } },
    onCompleted(data) {
      setCPData(data.allContentProviders.items);
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    setIsloading(true);
    refetch();
  }, [filter]);

  const handleSearchWithTime = (date: RangeValue<dayjs.Dayjs>) => {
    if (date) {
      setFilter({
        ...filter,
        page: 1,
        startDay: date ? dayjs(date[0]).format(FORMAT_PARAM_DATE) : '',
        endDay: date ? dayjs(date[1]).format(FORMAT_PARAM_DATE) : '',
      });
    } else {
      setFilter({
        ...filter,
        page: 1,
        startDay: undefined,
        endDay: undefined,
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'CP',
      dataIndex: 'contentProviderName',
      key: 'contentProviderName',
    },
    {
      title: 'CTKM',
      dataIndex: 'promotion',
      key: 'promotion',
      render: (promotion) => <>{promotion?.name} </>,
    },
    {
      title: 'Thuê bao',
      dataIndex: 'isdn',
      key: 'isdn',
    },
    {
      title: 'Mã gói',
      dataIndex: 'packageCode',
      key: 'packageCode',
    },
    {
      title: 'Loại hình trả thưởng',
      dataIndex: 'reward',
      key: 'reward',
      render: (reward) => <>{TYPE_REWARD_PROMOTION.find((item) => item.key === reward?.type)?.value ?? '_'}</>,
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'promotion',
      key: 'promotion',
      render: (promotion) => <>{dayjs(promotion?.startTime).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'promotion',
      key: 'promotion',
      render: (promotion) => <>{dayjs(promotion?.endTime).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
    {
      title: 'Mức trả thưởng',
      dataIndex: 'reward',
      key: 'reward',
      render: (reward) => <>{reward?.value}</>,
    },
    {
      title: 'Thời gian trúng thưởng',
      dataIndex: 'eligibleDate',
      key: 'eligibleDate',
      width: 200,
      render: (arrDate) => <>{dayjs(arrDate).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
  ];

  const handleArrCp = () => {
    const arrCP = promotionData
      .find((item) => item?._id === filter?.campaignId)
      ?.promotionStrategies.filter(
        (item, index, self) => index === self.findIndex((t) => t.contentProviderId === item.contentProviderId),
      );

    return arrCP;
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(``)}>
                Đủ điều kiện nhận thưởng
              </p>
            ),
          },
          { title: 'Danh sách' },
        ]}
      />
      <Card size="small">
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item
              required={false}
              label={<span>CTKM</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
            >
              <Select
                allowClear
                onChange={(e) => {
                  if (!userDetail?.contentProviderId) {
                    form.setFieldsValue({ cp: undefined });
                    if (e) {
                      setFilter({ ...filter, page: 1, campaignId: e, contentProviderId: undefined });
                    } else {
                      setFilter({ ...filter, page: 1, campaignId: undefined, contentProviderId: undefined });
                    }
                  } else {
                    setFilter({ ...filter, page: 1, campaignId: e });
                  }
                }}
                placeholder="Tất cả"
              >
                {promotionData.map(({ _id, name }) => (
                  <Select.Option key={_id} value={_id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {!userDetail?.contentProviderId && (
            <Col span={4}>
              <Form form={form}>
                <Form.Item
                  required={false}
                  label={<span>CP</span>}
                  name="cp"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ width: '100%' }}
                >
                  <Select
                    allowClear
                    placeholder="Tất cả"
                    onChange={(e) => {
                      if (e) {
                        setFilter({ ...filter, page: 1, contentProviderId: e });
                      } else {
                        setFilter({ ...filter, page: 1, contentProviderId: undefined });
                      }
                    }}
                    disabled={!filter?.campaignId}
                  >
                    {(handleArrCp() ?? []).map(({ contentProviderId }) => (
                      <Select.Option value={contentProviderId}>
                        {cpData.find((item) => item._id === contentProviderId)?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          )}

          <Col span={6}>
            <Form.Item
              required={false}
              label={<span>Tìm theo thời gian</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
              name="searchTableByDate"
            >
              <DatePicker.RangePicker
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                onChange={(date: RangeValue<dayjs.Dayjs>) => handleSearchWithTime(date)}
                disabledDate={(current: dayjs.Dayjs | null) =>
                  current ? current.isAfter(dayjs().endOf('day')) : false
                }
                defaultValue={[dayjs(filter?.startDay), dayjs(filter?.endDay)]}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label={<span></span>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                disabled={!filter?.campaignId || !filter?.contentProviderId}
                onClick={() => handleExportExcel()}
              >
                Xuất excel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Container title="Danh sách đủ điều kiện nhận thưởng">
        <Table columns={columns} dataSource={tableData} rowKey="_id" pagination={pagination} loading={isloading} />
      </Container>
    </Space>
  );
};
