import { Breadcrumb, Tabs, TabsProps } from 'antd';
import Page from 'src/components/Container';
import HistoryRecievedAchievement from './HistoryRecievedAchievement';
import HistoryReceivedMilestone from './HistoryReceivedMilestone';

export default () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Lịch sử nhận thưởng thành tựu',
      children: <HistoryRecievedAchievement />,
    },
    {
      key: '2',
      label: 'Lịch sử nhận thưởng mốc',
      children: <HistoryReceivedMilestone />,
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <p style={{ cursor: 'default' }}>Lịch sử nhận</p>,
          },
          { title: 'Danh sách' },
        ]}
      />
      <Page title="Lịch sử nhận">
        <Tabs defaultActiveKey="1" items={items} />
      </Page>
    </>
  );
};
