import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Tabs, Upload, UploadFile } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import {
  CREATE_SUCCESS_MESSAGE,
  FIELD_REQUIRED,
  IMAGE_SIZE_LESS_5MB,
  INVALID_IMAGE_FORMAT,
  MAX_255_CHARACTER,
  MAX_300_CHARACTER,
  NO_EMOJI,
  NO_SPACE,
  NO_SPECIAL_CHARACTER_NO_EMOJI,
  NUMBER_INTEGER,
  UPDATE_SUCCESS_MESSAGE,
} from 'src/constants/message';
import { useCreatePromotionCampaignMutation } from 'src/graphql/mutations/createPromotionCampaign.graphql';
import { useUpdatePromotionCampaignMutation } from 'src/graphql/mutations/updatePromotionCampaign.graphql';
import { CreatePromotionCampaignDto, PromotionCampaign, PromotionStrategy } from 'src/graphql/type.interface';
import { FORMAT_DATE } from 'src/constants/common';
import dayjs from 'dayjs';
import PromotionStrategiesTabs from 'src/components/Promotion/PromotionStrategiesTabs';
import { number, object, string } from 'yup';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import uploadFile from 'src/apis/service/uploadFile';
import { beforeUploadImage } from 'src/constants/function';
import { MAX_IMAGE_SIZE, multiSpaceRegex, regex, REGEX_NO_SPECIAL_CHARACTER_NO_EMOJI } from 'src/constants/constants';
import TextArea from 'antd/es/input/TextArea';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface TabItem {
  label: string;
  children: React.ReactNode;
  key: string;
  closable: boolean;
}

interface RewardedTimeRangeType {
  startTime: any;
  endTime: any;
}

type Props = { currentPromotion?: PromotionCampaign; isDetail?: boolean };

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      name: string()
        .required(FIELD_REQUIRED)
        .max(255, MAX_255_CHARACTER)
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
        .matches(regex, NO_EMOJI),
      startTime: string().required(FIELD_REQUIRED),
      endTime: string().required(FIELD_REQUIRED),
      nameDispatch: string()
        .required(FIELD_REQUIRED)
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
        .matches(REGEX_NO_SPECIAL_CHARACTER_NO_EMOJI, NO_SPECIAL_CHARACTER_NO_EMOJI),
      numberAwards: number().required(FIELD_REQUIRED).min(1, 'Số phải lớn hơn 1').integer(NUMBER_INTEGER),
      // logo: object()
      //   .test({
      //     test: (value: any) => {
      //       // magic validate form upload
      //       return value?.fileList?.length ? true : false;
      //     },
      //     message: FIELD_REQUIRED,
      //   })
      //   .test({
      //     test: (value: any) => (value?.file ? /.+\.(png|jpe?g|svg)$/i.test(value?.file?.name) : true),
      //     message: INVALID_IMAGE_FORMAT,
      //   })
      //   .test({
      //     test: (value: any) => (value.file ? value?.file?.originFileObj?.size <= MAX_IMAGE_SIZE : true),
      //     message: IMAGE_SIZE_LESS_5MB,
      //   }),
      url: string()
        .test({
          test: (value: any) => {
            if (!value) {
              return true;
            } else {
              return /^(https:\/\/|http:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/.test(value.trimEnd());
            }
          },
          message: 'Đường dẫn không hợp lệ',
        })
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE }),
      description: string()
        .required(FIELD_REQUIRED)
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
        .max(300, MAX_300_CHARACTER),
    }).validateAt(field, { [field]: value });
  },
};

export default ({ currentPromotion, isDetail }: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [promotionStrategy, setPromotionStrategy] = useState<PromotionStrategy[]>([]);

  const [activeKey, setActiveKey] = useState<string>('0');
  const [items, setItems] = useState<TabItem[]>([]);
  const newTabIndex = useRef<number>(0);

  const [formData, setFormData] = useState<any>([]);
  const [dataPackage, setDataPackage] = useState<string[]>([]);
  const [dataContentProvider, setDataContentProvider] = useState<string[]>([]);
  const [arrayImage, setArrayImage] = useState<UploadFile[]>([]);
  const [urlLogo, setUrlLogo] = useState<string | null>(null);
  const [startTimeCreate, setStartTimeCreate] = useState<any>();
  const [endTimeCreate, setEndTimeCreate] = useState<any>();
  const [dataRewardedTimeRange, setDataRewardedTimeRange] = useState<RewardedTimeRangeType[]>([]);
  const [endTimeReward, setEndTimeReward] = useState<any>();

  const handleInputChange = (tab: number, field: any, value: any, isEdit: boolean) => {
    setFormData((prevFormData: any[]) => {
      const updatedTabData = {
        ...prevFormData[tab],
        ...(field === 'packageCode' && { [field]: value }),
        ...(field === 'typeStrategy' && { [field]: value }),
        ...(field === 'contentProviderId' && { [field]: value }),
        ...(field === 'countConsecutiveRenewal' && { [field]: Number(value) }),
        ...(field === 'commandCode' && { [field]: value }),
        ...((field === 'value' || field === 'type' || field === 'addReward') && {
          reward: {
            ...(field === 'value' && { ...prevFormData[tab]?.reward, value: Number(value) }),
            ...(field === 'type' && { ...prevFormData[tab]?.reward, type: value }),
            ...(field === 'addReward' && { ...prevFormData[tab]?.reward, addReward: value }),
          },
        }),
        ...((field === 'mtRegisterRenew' || field === 'mtReward') && {
          messageMT: {
            ...(field === 'mtRegisterRenew' && { ...prevFormData[tab]?.messageMT, mtRegisterRenew: value }),
            ...(field === 'mtReward' && { ...prevFormData[tab]?.messageMT, mtReward: value }),
          },
        }),
        ...(currentPromotion && { campaignId: currentPromotion?._id }),
        ...(isEdit && { _id: promotionStrategy[tab]._id }),
      };

      return {
        ...prevFormData,
        [tab]: updatedTabData,
      };
    });
  };

  useEffect(() => {
    if (promotionStrategy.length > 0) {
      const initialItems = promotionStrategy.map((item, index) => {
        return {
          label: `Kịch bản ${index + 1}`,
          children: (
            <PromotionStrategiesTabs
              form={form}
              dataPackage={dataPackage}
              dataContentProvider={dataContentProvider}
              data={item}
              onInputChange={handleInputChange}
              tabKey={`${index}`}
              isEdit={true}
              isDetail={isDetail}
            />
          ),
          key: `${index}`,
          closable: index === 0 ? false : true,
        };
      });
      setItems(initialItems);
      setActiveKey(initialItems[0]?.key);
    } else {
      const defaultItems = [
        {
          label: `Kịch bản mới`,
          children: (
            <PromotionStrategiesTabs
              form={form}
              dataPackage={dataPackage}
              dataContentProvider={dataContentProvider}
              onInputChange={handleInputChange}
              tabKey={'1'}
              isEdit={false}
              isDetail={isDetail}
            />
          ),
          key: `1`,
          closable: false,
        },
      ];
      setItems(defaultItems);
      setActiveKey(defaultItems[0]?.key);
    }
  }, [promotionStrategy]);

  useEffect(() => {
    setDataPackage(
      Object.keys(formData)
        .map((key) => formData[key])
        ?.map((item) => item?.packageCode),
    );
    setDataContentProvider(
      Object.keys(formData)
        .map((key) => formData[key])
        ?.map((item) => item?.contentProviderId),
    );
  }, [formData]);

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...items];

    newPanes.push({
      label: `Kịch bản mới`,
      children: (
        <PromotionStrategiesTabs
          form={form}
          dataPackage={dataPackage}
          dataContentProvider={dataContentProvider}
          onInputChange={handleInputChange}
          tabKey={`${Object.keys(formData).map((key) => formData[key]).length + 1}`}
          isEdit={false}
          isDetail={isDetail}
        />
      ),
      key: newActiveKey,
      closable: true,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }

    setItems(newPanes);
    setActiveKey(newActiveKey);

    Object.keys(formData).map((key) => formData[key]).length > lastIndex && formData?.splice(lastIndex + 1, 1);
    setFormData(formData);
  };

  const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  const [createPromotionCampaignMutation] = useCreatePromotionCampaignMutation({
    onCompleted() {
      enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.promotion.list);
    },
    onError({ message }) {
      message === 'Tên chương trình đã tồn tại' && form.setFields([{ name: 'name', errors: [`${message}`] }]);
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const [updatePromotionCampaignMutation] = useUpdatePromotionCampaignMutation({
    onCompleted() {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.promotion.list);
    },
    onError({ message }) {
      message === 'Tên chương trình đã tồn tại' && form.setFields([{ name: 'name', errors: [`${message}`] }]);
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const customRequestUpload = async (options: UploadRequestOption) => {
    const { file } = options;
    const filesData = new FormData();

    filesData.append(`file`, file);
    const res = await uploadFile(filesData);

    if (res) {
      setUrlLogo(res.data.preview);
      setArrayImage([
        {
          uid: '1',
          name: res.data.path,
          url: res.data.preview,
          status: 'done',
        } as UploadFile,
      ]);
    }
  };

  const removeFields = (promotionStrategy: PromotionStrategy[]): PromotionStrategy[] => {
    const fieldsToRemove = ['updatedAt', 'updatedBy', '__typename', 'createdAt', 'createdBy', 'isDeleted'];

    const cleanObject = (obj: any): any => {
      return Object.fromEntries(Object.entries(obj).filter(([key]) => !fieldsToRemove.includes(key)));
    };

    return promotionStrategy.map((strategy) => {
      const cleanedStrategy = cleanObject(strategy);
      if (cleanedStrategy.messageMT) {
        cleanedStrategy.messageMT = cleanObject(cleanedStrategy.messageMT);
      }
      if (cleanedStrategy.reward) {
        cleanedStrategy.reward = cleanObject(cleanedStrategy.reward);
      }
      return cleanedStrategy;
    });
  };

  useEffect(() => {
    if (currentPromotion) {
      const {
        name,
        startTime,
        endTime,
        promotionStrategies,
        logo,
        url,
        rewardedTimeRange,
        nameDispatch,
        numberAwards,
        description,
      } = currentPromotion;

      setPromotionStrategy(promotionStrategies);

      setFormData(removeFields(promotionStrategies));

      setDataPackage(promotionStrategies?.map((item) => item?.packageCode));

      setDataContentProvider(promotionStrategies?.map((item) => item?.contentProviderId as string));

      rewardedTimeRange &&
        setEndTimeReward(
          rewardedTimeRange.length > 0
            ? dayjs(rewardedTimeRange[rewardedTimeRange?.length - 1].endTime)
            : dayjs(endTime).add(1, 'day'),
        );

      const parsedTimeRanges =
        (rewardedTimeRange ?? []).length > 0
          ? rewardedTimeRange?.map((range) => ({
              startTime: dayjs(range.startTime),
              endTime: dayjs(range.endTime),
            }))
          : [
              {
                startTime: dayjs(startTime),
                endTime: dayjs(endTime).add(1, 'day'),
              },
            ];

      rewardedTimeRange && setDataRewardedTimeRange(rewardedTimeRange);

      if (logo) {
        setArrayImage([
          {
            uid: '1',
            name: logo,
            url: logo,
            status: 'done',
          } as UploadFile,
        ]);
        setUrlLogo(logo as string);
      }

      setStartTimeCreate(startTime);
      setEndTimeCreate(endTime);

      form.setFieldsValue({
        name,
        logo: logo
          ? {
              fileList: [
                {
                  uid: '1',
                  name: logo,
                  url: logo,
                  status: 'done',
                } as UploadFile,
              ],
            }
          : undefined,
        startTime: dayjs(startTime),
        endTime: dayjs(endTime),
        url: url ?? undefined,
        rewardedTimeRange: parsedTimeRanges,
        nameDispatch,
        numberAwards,
        description,
      });
    }
  }, [currentPromotion]);

  const onFinish = async (form: CreatePromotionCampaignDto) => {
    const { name, description, startTime, endTime, url, rewardedTimeRange, nameDispatch, numberAwards } = form;

    const parsedTimeRanges = rewardedTimeRange?.map((range) => ({
      startTime: dayjs(range.startTime).startOf('day'),
      endTime: dayjs(range.endTime).endOf('day'),
    }));

    if (currentPromotion) {
      updatePromotionCampaignMutation({
        variables: {
          input: {
            _id: currentPromotion._id,
            name: name,
            startTime: dayjs(startTime).startOf('day'),
            endTime: dayjs(endTime).endOf('day'),
            strategies: Object.keys(formData).map((key) => formData[key]),
            logo: (urlLogo as string) ?? undefined,
            url: url && url !== '' ? url : undefined,
            rewardedTimeRange: parsedTimeRanges,
            nameDispatch,
            numberAwards,
            description,
          },
        },
      });
    } else {
      createPromotionCampaignMutation({
        variables: {
          input: {
            name: name,
            startTime: dayjs(startTime).startOf('day'),
            endTime: dayjs(endTime).endOf('day'),
            strategies: Object.keys(formData).map((key) => formData[key]),
            logo: (urlLogo as string) ?? undefined,
            url: url && url !== '' ? url : undefined,
            rewardedTimeRange: parsedTimeRanges,
            nameDispatch,
            numberAwards,
            description,
          },
        },
      });
    }
  };

  const handleCreateStartTime = (e: any) => {
    setStartTimeCreate(e);
    if (form.getFieldValue('rewardedTimeRange') && form.getFieldValue('rewardedTimeRange').length > 0) {
      const newDataRewardedTimeRange = [...form.getFieldValue('rewardedTimeRange')];
      newDataRewardedTimeRange[0].startTime = e;
      setDataRewardedTimeRange(newDataRewardedTimeRange);
      form.setFieldValue('rewardedTimeRange', newDataRewardedTimeRange);
    }
  };

  const handleEndTimeReward = (e: any, index: any) => {
    const newDataRewardedTimeRange = [...form.getFieldValue('rewardedTimeRange')];

    if (index < form.getFieldValue('rewardedTimeRange').length - 1) {
      newDataRewardedTimeRange[index + 1].startTime = e;
    } else if (index === 0) {
      newDataRewardedTimeRange[index].startTime = startTimeCreate;
    } else if (index === form.getFieldValue('rewardedTimeRange').length - 1) {
      newDataRewardedTimeRange[index].startTime = dayjs(newDataRewardedTimeRange[index - 1].endTime).add(1, 'day');
    } else {
      newDataRewardedTimeRange[index].startTime = endTimeReward;
    }

    setDataRewardedTimeRange(newDataRewardedTimeRange);
    form.setFieldValue('rewardedTimeRange', newDataRewardedTimeRange);
    setEndTimeReward(dayjs(e).add(1, 'day'));
  };

  const disabledEndDate = (endDate: any) => {
    const startTime = form.getFieldValue('startTime');

    const now = new Date();

    now.setDate(now.getDate() + 1);

    if (!endDate) {
      return false;
    }

    const startDateObj = new Date(endDate);
    if (startDateObj.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)) {
      return true;
    }

    if (startTime && endDate.isBefore(dayjs(startTime).add(1, 'day'))) {
      return true;
    }
    return false;
  };

  const disabledStartDate = (startDate: any) => {
    const endDate = form.getFieldValue('endTime');
    const now = new Date();

    // Thêm 1 ngày vào ngày hiện tại
    now.setDate(now.getDate() + 1);

    if (!startDate) {
      return false;
    }

    // Disable dates before current date + 1 day
    const startDateObj = new Date(startDate);
    if (startDateObj.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)) {
      return true;
    }

    // Disable start date if it's after the end date
    if (endDate && startDate.isAfter(endDate)) {
      return true;
    }

    return false;
  };

  const disableStartDateRewardedTimeRange = (endDate: any, index: number) => {
    const startTime = index > 0 ? dataRewardedTimeRange[index - 1].endTime : startTimeCreate;
    if (!startTime || !endDate) {
      return false;
    }
    return endDate.isBefore(startTime);
  };

  const disableEndDateRewardedTimeRange = (startDate: any, index: number) => {
    const endTime = index > 0 ? dataRewardedTimeRange[index - 1].endTime : startTimeCreate;
    if (!endTime || !startDate) {
      return false;
    }

    if (index < form.getFieldValue('rewardedTimeRange').length - 1) {
      const startTime = endTimeCreate;
      return startDate.isAfter(dayjs(startTime).subtract(1, 'day')) || startDate.isBefore(dayjs(endTime).add(1, 'day'));
    }

    return startDate.isBefore(dayjs(endTime).add(1, 'day'));
  };

  const handleChangeName = () => {
    form.setFields([{ name: 'name', errors: undefined }]);
  };

  const validateEndTime = (rule: any, value: any, callback: any) => {
    const index = rule.field.match(/\d+/)[0];
    const startTime = dataRewardedTimeRange[index]?.startTime;

    if (startTime && value && value.isBefore(startTime)) {
      callback('Thời gian kết thúc phải lớn hơn thời gian bắt đầu');
    } else {
      callback();
    }
  };

  return (
    <Row>
      <Col offset={3} span={18}>
        <Form form={form} onFinish={onFinish}>
          <div
            style={{
              border: '1px solid #1677ff',
              padding: '2%',
              borderRadius: '10px',
              margin: '4% 0',
              position: 'relative',
            }}
          >
            <p
              style={{
                position: 'absolute',
                top: '-20px',
                left: '10',
                background: 'white',
                fontSize: '18px',
                color: '#1677ff',
              }}
            >
              Thông tin chung
            </p>
            <Form.Item
              label={
                <span>
                  Tên chương trình<span style={{ color: 'red' }}>*</span>
                </span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="name"
              rules={[yupSync]}
            >
              <Input onChange={handleChangeName} disabled={isDetail} />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Số CV<span style={{ color: 'red' }}>*</span>
                </span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="nameDispatch"
              rules={[yupSync]}
            >
              <Input disabled={isDetail} />
            </Form.Item>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Thời gian bắt đầu<span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="startTime"
                  rules={[yupSync]}
                >
                  <DatePicker
                    format={FORMAT_DATE}
                    style={{ width: '100%' }}
                    disabledDate={disabledStartDate}
                    onChange={(e) => handleCreateStartTime(e)}
                    disabled={isDetail}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Thời gian kết thúc<span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="endTime"
                  rules={[yupSync]}
                >
                  <DatePicker
                    format={FORMAT_DATE}
                    style={{ width: '100%' }}
                    disabledDate={disabledEndDate}
                    onChange={(e) => setEndTimeCreate(e)}
                    disabled={isDetail}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <h3>Trả thưởng</h3>
            </div>

            <Form.Item
              label={
                <span>
                  Số lượng trả thưởng<span style={{ color: 'red' }}>*</span>
                </span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="numberAwards"
              rules={[yupSync]}
            >
              <InputNumber style={{ width: '100%' }} disabled={isDetail} />
            </Form.Item>

            {startTimeCreate && endTimeCreate && (
              <Form.Item
                labelCol={{ span: 24 }}
                label={
                  <div>
                    Thời gian trả thưởng <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Form.List name="rewardedTimeRange" initialValue={[{ startTime: startTimeCreate, endTime: '' }]}>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((field) => {
                          return (
                            <div key={field.name} style={{ display: 'flex', gap: '20px' }}>
                              <Form.Item
                                required={false}
                                name={[field.name, 'startTime']}
                                labelCol={{ span: 24 }}
                                style={{ width: '100%' }}
                              >
                                <DatePicker
                                  style={{ width: '100%' }}
                                  format={FORMAT_DATE}
                                  disabledDate={(e) => disableStartDateRewardedTimeRange(e, field.name)}
                                  defaultValue={form.getFieldValue('rewardedTimeRange')[field.name - 1]?.endTime}
                                  disabled={true}
                                />
                              </Form.Item>
                              <Form.Item
                                required={false}
                                name={[field.name, 'endTime']}
                                labelCol={{ span: 24 }}
                                style={{ width: '100%' }}
                                rules={[
                                  {
                                    validator: validateEndTime,
                                  },
                                  { required: true, message: FIELD_REQUIRED },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: '100%' }}
                                  format={FORMAT_DATE}
                                  disabledDate={(e) => disableEndDateRewardedTimeRange(e, field.name)}
                                  onChange={(e) => handleEndTimeReward(e, field.name)}
                                  disabled={isDetail}
                                />
                              </Form.Item>
                              {field.name > 0 && (
                                <Form.Item labelCol={{ span: 24 }} style={{ width: '5%' }}>
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      remove(field.name);
                                      setDataRewardedTimeRange(form.getFieldValue('rewardedTimeRange'));
                                      setEndTimeReward(
                                        dayjs(
                                          form.getFieldValue('rewardedTimeRange')[
                                            form.getFieldValue('rewardedTimeRange').length - 1
                                          ].endTime,
                                        ).add(1, 'day'),
                                      );

                                      const newDataRewardedTimeRange = [...form.getFieldValue('rewardedTimeRange')];

                                      if (field.name === 0) {
                                        newDataRewardedTimeRange[0].startTime = dayjs(startTimeCreate);
                                      }
                                      if (field.name < newDataRewardedTimeRange.length) {
                                        newDataRewardedTimeRange[field.name].startTime = dayjs(
                                          form.getFieldValue('rewardedTimeRange')[field.name - 1].endTime,
                                        ).add(1, 'day');
                                      }
                                      setDataRewardedTimeRange(newDataRewardedTimeRange);
                                      form.setFieldValue('rewardedTimeRange', newDataRewardedTimeRange);
                                    }}
                                    icon={<MinusOutlined />}
                                    disabled={isDetail}
                                  ></Button>
                                </Form.Item>
                              )}
                            </div>
                          );
                        })}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add({ startTime: endTimeReward ?? '', endTime: '' });
                                setDataRewardedTimeRange(form.getFieldValue('rewardedTimeRange'));
                              }}
                              icon={<PlusOutlined />}
                              disabled={
                                dayjs(dataRewardedTimeRange[dataRewardedTimeRange.length - 1]?.endTime) >
                                  dayjs(endTimeCreate) || isDetail
                              }
                            ></Button>
                          </Form.Item>
                        </div>
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
            )}

            <Form.Item
              label={<span>Ảnh logo</span>}
              labelCol={{ span: 24 }}
              name="logo"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value && value.fileList.length > 0) {
                      if (value.file && !/.+\.(png|jpe?g|svg)$/i.test(value.file.name)) {
                        return Promise.reject(new Error(INVALID_IMAGE_FORMAT));
                      }
                      if (value.file && value.file.size > MAX_IMAGE_SIZE) {
                        return Promise.reject(new Error(IMAGE_SIZE_LESS_5MB));
                      } else {
                        return Promise.resolve();
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Upload
                listType="picture-card"
                fileList={arrayImage}
                beforeUpload={beforeUploadImage}
                customRequest={customRequestUpload}
                onRemove={() => {
                  setArrayImage([]);
                  setUrlLogo(null);
                }}
                disabled={isDetail}
              >
                <PlusOutlined rev={undefined} />
              </Upload>
            </Form.Item>
            <Form.Item
              label={<span>Đường dẫn</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="url"
              rules={[yupSync]}
            >
              <Input disabled={isDetail} />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Mô tả<span style={{ color: 'red' }}>*</span>
                </span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="description"
              rules={[yupSync]}
            >
              <TextArea disabled={isDetail} />
            </Form.Item>
          </div>

          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="strategies">
            <Tabs
              type="editable-card"
              onChange={onChange}
              activeKey={activeKey}
              onEdit={isDetail ? () => {} : onEdit}
              items={items}
            />
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
            {isDetail ? (
              <Button type="primary" onClick={() => navigate(PATH.promotion.list)}>
                Đóng
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            )}
          </div>
        </Form>
      </Col>
    </Row>
  );
};
