import { useEffect, useState } from 'react';
import { object, string } from 'yup';
import { Helmet } from 'react-helmet';
import { LockOutlined } from '@ant-design/icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Card, Form, Input, message } from 'antd';
import {
  CHANGE_PASSWORD_SUCCESS_MESSAGE,
  CONFIRM_PASSWORD_FORM_MESSAGE,
  FIELD_REQUIRED,
  NO_EMOJI,
  NO_SPACE,
  PASSWORD_MESSAGE_TOO_LONG,
  PASSWORD_MESSAGE_TOO_SHORT,
} from 'src/constants/message';
import { getToken, removeToken } from 'src/utils/jwt';
import { useDetailStore, useLoginStore } from 'src/stores';
import authAdministrator from 'src/apis/service/administrator';
import { multiSpaceRegex, regex } from 'src/constants/constants';
import OTPInput from 'react-otp-input';
import { ENV } from 'src/constants/common';

type TypeForm = {
  username: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  phone: string;
  otpPhone: string;
};

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      otpPhone: string()
        .test({
          test: (value: any) => /^84\d{9}$/.test(value),
          message: 'Số điện thoại phải dạng số và bắt đầu bằng 84',
        })
        .required(FIELD_REQUIRED),
      oldPassword: string().required(FIELD_REQUIRED),
      newPassword: string()
        .required(FIELD_REQUIRED)
        .min(8, PASSWORD_MESSAGE_TOO_SHORT)
        .max(40, PASSWORD_MESSAGE_TOO_LONG)
        .test({ test: (value) => !multiSpaceRegex.test(value), message: NO_SPACE })
        .test({
          test: (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/.test(value),
          message: 'Mật khẩu phải có ít nhất 1 kí tự viết hoa, 1 kí tự viết thường và 1 kí tự đặc biệt',
        })
        .matches(regex, NO_EMOJI),
      confirmPassword: string().required(FIELD_REQUIRED),
      otp: string().required(FIELD_REQUIRED),
    }).validateAt(field, { [field]: value });
  },
};

export default () => {
  const [form] = Form.useForm();
  const { userDetail } = useDetailStore();
  const { setUserInfo } = useLoginStore();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const navigate = useNavigate();

  const handleSendOTP = async () => {
    const res = await authAdministrator.getOTP({ type: 'PHONE', phone: phoneNumber });
    setOtp(res.data);
  };

  ENV !== 'production' &&
    useEffect(() => {
      if (otp) {
        form.setFieldsValue({ otp });
      }
    }, [otp]);

  const onFinish = async ({ oldPassword, newPassword, confirmPassword }: TypeForm) => {
    setIsLoading(true);
    if (!getToken()) {
      const res = await authAdministrator.resetPasswordWithOTP({
        oldPassword,
        newPassword,
        confirmPassword,
        phone: phoneNumber,
        otpPhone: otp,
      });

      if (res.success) {
        message.success(CHANGE_PASSWORD_SUCCESS_MESSAGE);
        navigate('/login', { replace: true });
        localStorage.removeItem('isFirstLogin');
        setUserInfo(null);
        removeToken();
        setIsLoading(false);
      } else {
        if (res.error === 'WRONG_PASSWORD' || res.error === 'INVALID_INPUT') {
          message.error('Trường mật khẩu chưa chính xác. Vui lòng kiểm tra lại');
        }
        setIsLoading(false);
      }
    } else {
      const res = await authAdministrator.resetPassword({
        oldPassword,
        newPassword,
        confirmPassword,
        username: userDetail?.fullName,
      });

      if (res.success) {
        message.success(CHANGE_PASSWORD_SUCCESS_MESSAGE);
        localStorage.removeItem('isFirstLogin');
        setUserInfo(null);
        removeToken();
        setIsLoading(false);
      } else {
        if (res.error === 'WRONG_PASSWORD' || res.error === 'INVALID_INPUT') {
          message.error('Trường mật khẩu chưa chính xác. Vui lòng kiểm tra lại');
        }
        setIsLoading(false);
      }
    }
  };

  if (!getToken() && window.location.pathname !== '/auth/reset-password') {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <>
      <Helmet>
        <title>Đổi mật khẩu</title>
      </Helmet>
      <div style={styleWrapper}>
        <div style={styleLogo}>
          <img style={{ width: '50%' }} src="/image/logo.svg" alt="" />
        </div>
        <Card style={styleCard}>
          <Form form={form} onFinish={onFinish} autoComplete="off">
            <div style={{ marginBottom: '6px' }}>
              Mật khẩu<span style={{ color: 'red' }}> *</span>
            </div>
            <Form.Item style={{ marginBottom: '16px' }} name="oldPassword" rules={[yupSync]}>
              <Input.Password placeholder="Nhập mật khẩu" prefix={<LockOutlined rev={undefined} />} size="large" />
            </Form.Item>

            <div style={{ marginBottom: '6px' }}>
              Mật khẩu mới<span style={{ color: 'red' }}> *</span>
            </div>
            <Form.Item name="newPassword" style={{ marginBottom: '16px' }} rules={[yupSync]}>
              <Input.Password placeholder="Nhập mật khẩu mới" prefix={<LockOutlined rev={undefined} />} size="large" />
            </Form.Item>

            <div style={{ marginBottom: '6px' }}>
              Xác nhận mật khẩu mới<span style={{ color: 'red' }}> *</span>
            </div>
            <Form.Item
              name="confirmPassword"
              style={{ marginBottom: '16px' }}
              rules={[
                yupSync,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(CONFIRM_PASSWORD_FORM_MESSAGE));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Nhập lại mật khẩu mới"
                prefix={<LockOutlined rev={undefined} />}
                size="large"
              />
            </Form.Item>

            {!getToken() && (
              <>
                <Form.Item
                  label={
                    <>
                      Số điện thoại <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  labelCol={{ span: 24 }}
                  style={{ marginBottom: '16px' }}
                  name="otpPhone"
                  rules={[yupSync]}
                >
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Nhập số điện thoại"
                      size="large"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <Button
                      style={{ height: '40px', color: 'white', backgroundColor: !phoneNumber ? '#d9d9d9' : '#1677ff' }}
                      onClick={() => handleSendOTP()}
                      htmlType="button"
                      disabled={!phoneNumber}
                    >
                      Gửi OTP
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <>
                      OTP <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  labelCol={{ span: 24 }}
                  name="otp"
                >
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      width: '65px',
                      height: '65px',
                      margin: '0 5px',
                      fontSize: '18px',
                      textAlign: 'center',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      outline: 'none',
                    }}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }} disabled={isLoading}>
                Đổi mật khẩu
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

const styleWrapper: React.CSSProperties = {
  width: 500,
  position: 'fixed',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const styleLogo: React.CSSProperties = {
  marginBottom: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const styleCard: React.CSSProperties = {
  boxShadow: '0 4px 16px 0 rgba(167, 175, 183, 0.33)',
};
