export enum PaymentBillingChannelEnum {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  WALLET = 'WALLET',
  BANK = 'BANK',
  INTERNATIONAL_CARD = 'INTERNATIONAL_CARD',
}

export enum PaymentTransactionStatusEnum {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}
