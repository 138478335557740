import { Breadcrumb } from 'antd';
import Container from '../../components/Container';
import PromotionForm from '../../sections/promotion/PromotionForm';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useState } from 'react';
import { usePromotionCampaignQuery } from 'src/graphql/queries/promotionCampaign.graphql';
import { PromotionCampaign } from 'src/graphql/type.interface';

export default () => {
  const navigate = useNavigate();
  const _id = useParams()._id as string;
  const [currentPromotion, setCurrentPromotion] = useState<PromotionCampaign>();

  usePromotionCampaignQuery({
    variables: { id: _id },
    onCompleted(data) {
      setCurrentPromotion(data.promotionCampaign);
    },
    onError({ message }) {
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate('/promotion/list')}>
                Khuyến mãi
              </p>
            ),
          },
          {
            title: `Chi tiết: ${currentPromotion?.name}`,
          },
        ]}
      />
      <Container title="Chi tiết khuyến mãi">
        <PromotionForm currentPromotion={currentPromotion} isDetail={true} />
      </Container>
    </>
  );
};
