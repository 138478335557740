import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamInTourOfflineQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type TeamInTourOfflineQueryResponse = { __typename?: 'Query' } & {
  teamInTourOffline: { __typename?: 'TeamInTourOffline' } & Pick<
    Types.TeamInTourOffline,
    | '_id'
    | 'avatar'
    | 'createdAt'
    | 'createdBy'
    | 'inMatch'
    | 'isDeleted'
    | 'isFinished'
    | 'name'
    | 'status'
    | 'tourOffline'
    | 'updatedAt'
    | 'updatedBy'
  > & {
      members?: Types.Maybe<
        Array<
          { __typename?: 'MemberTeamInTournamentOffline' } & Pick<
            Types.MemberTeamInTournamentOffline,
            'name' | 'role' | 'uid'
          >
        >
      >;
    };
};

export const TeamInTourOfflineDocument = gql`
  query teamInTourOffline($id: String!) {
    teamInTourOffline(id: $id) {
      _id
      avatar
      createdAt
      createdBy
      inMatch
      isDeleted
      isFinished
      members {
        name
        role
        uid
      }
      name
      status
      tourOffline
      updatedAt
      updatedBy
    }
  }
`;
export function useTeamInTourOfflineQuery(
  baseOptions: Apollo.QueryHookOptions<TeamInTourOfflineQueryResponse, TeamInTourOfflineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamInTourOfflineQueryResponse, TeamInTourOfflineQueryVariables>(
    TeamInTourOfflineDocument,
    options,
  );
}
export function useTeamInTourOfflineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamInTourOfflineQueryResponse, TeamInTourOfflineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamInTourOfflineQueryResponse, TeamInTourOfflineQueryVariables>(
    TeamInTourOfflineDocument,
    options,
  );
}
export function useTeamInTourOfflineSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TeamInTourOfflineQueryResponse, TeamInTourOfflineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TeamInTourOfflineQueryResponse, TeamInTourOfflineQueryVariables>(
    TeamInTourOfflineDocument,
    options,
  );
}
export type TeamInTourOfflineQueryHookResult = ReturnType<typeof useTeamInTourOfflineQuery>;
export type TeamInTourOfflineLazyQueryHookResult = ReturnType<typeof useTeamInTourOfflineLazyQuery>;
export type TeamInTourOfflineSuspenseQueryHookResult = ReturnType<typeof useTeamInTourOfflineSuspenseQuery>;
export type TeamInTourOfflineQueryResult = Apollo.QueryResult<
  TeamInTourOfflineQueryResponse,
  TeamInTourOfflineQueryVariables
>;
