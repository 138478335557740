import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AchievementQueryVariables = Types.Exact<{
  _id: Types.Scalars['String']['input'];
}>;

export type AchievementQueryResponse = { __typename?: 'Query' } & {
  achievement: { __typename?: 'AchievementCustom' } & Pick<
    Types.AchievementCustom,
    | '_id'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'isDeleted'
    | 'levelMilestone'
    | 'name'
    | 'performs'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  > & {
      completionQuantity?: Types.Maybe<
        { __typename?: 'AchievementCompletionQuantity' } & Pick<
          Types.AchievementCompletionQuantity,
          'quantity' | 'type'
        >
      >;
      reward: { __typename?: 'AchievementReward' } & Pick<Types.AchievementReward, 'type' | 'value'>;
    };
};

export const AchievementDocument = gql`
  query achievement($_id: String!) {
    achievement(_id: $_id) {
      _id
      completionQuantity {
        quantity
        type
      }
      createdAt
      createdBy
      description
      isDeleted
      levelMilestone
      name
      performs
      reward {
        type
        value
      }
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useAchievementQuery(
  baseOptions: Apollo.QueryHookOptions<AchievementQueryResponse, AchievementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AchievementQueryResponse, AchievementQueryVariables>(AchievementDocument, options);
}
export function useAchievementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AchievementQueryResponse, AchievementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AchievementQueryResponse, AchievementQueryVariables>(AchievementDocument, options);
}
export function useAchievementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AchievementQueryResponse, AchievementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AchievementQueryResponse, AchievementQueryVariables>(AchievementDocument, options);
}
export type AchievementQueryHookResult = ReturnType<typeof useAchievementQuery>;
export type AchievementLazyQueryHookResult = ReturnType<typeof useAchievementLazyQuery>;
export type AchievementSuspenseQueryHookResult = ReturnType<typeof useAchievementSuspenseQuery>;
export type AchievementQueryResult = Apollo.QueryResult<AchievementQueryResponse, AchievementQueryVariables>;
