import { RcFile } from 'antd/es/upload';
import { MAX_IMAGE_SIZE } from './constants';

export const beforeUploadImage = async (file: RcFile) => {
  const isImage = file.type.includes('image');
  const typeImage =
    file.type.includes('png') || file.type.includes('jpeg') || file.type.includes('svg') || file.type.includes('gif');
  const sizeImage = file?.size <= MAX_IMAGE_SIZE;

  if (!isImage || !sizeImage || !typeImage) {
    return false;
  }

  return true;
};
