import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAchievementMutationVariables = Types.Exact<{
  input: Types.UpdateAchievementDto;
}>;

export type UpdateAchievementMutationResponse = { __typename?: 'Mutation' } & {
  updateAchievement: { __typename?: 'Achievement' } & Pick<
    Types.Achievement,
    | '_id'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'isDeleted'
    | 'name'
    | 'performs'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  > & {
      completionQuantity?: Types.Maybe<
        { __typename?: 'AchievementCompletionQuantity' } & Pick<
          Types.AchievementCompletionQuantity,
          'quantity' | 'type'
        >
      >;
      reward: { __typename?: 'AchievementReward' } & Pick<Types.AchievementReward, 'type' | 'value'>;
    };
};

export const UpdateAchievementDocument = gql`
  mutation updateAchievement($input: UpdateAchievementDto!) {
    updateAchievement(input: $input) {
      _id
      completionQuantity {
        quantity
        type
      }
      createdAt
      createdBy
      description
      isDeleted
      name
      performs
      reward {
        type
        value
      }
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useUpdateAchievementMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAchievementMutationResponse, UpdateAchievementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAchievementMutationResponse, UpdateAchievementMutationVariables>(
    UpdateAchievementDocument,
    options,
  );
}
export type UpdateAchievementMutationHookResult = ReturnType<typeof useUpdateAchievementMutation>;
export type UpdateAchievementMutationResult = Apollo.MutationResult<UpdateAchievementMutationResponse>;
export type UpdateAchievementMutationOptions = Apollo.BaseMutationOptions<
  UpdateAchievementMutationResponse,
  UpdateAchievementMutationVariables
>;
