import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type Info = Record<string, any> | null;

interface UserDetailState {
  userDetail: Info;
  setUserDetail: (info: Info) => void;
}

const useDetailStore = create<UserDetailState>()(
  persist((set) => ({ userDetail: null, setUserDetail: (detail) => set(() => ({ userDetail: detail })) }), {
    name: 'userDetail',
  }),
);

export default useDetailStore;
