import { Card } from 'antd';
import { Helmet } from 'react-helmet';
import React, { ReactNode, forwardRef } from 'react';

interface Props {
  children: ReactNode;
  title: string;
  style?: React.CSSProperties;
}

export default forwardRef<HTMLDivElement, Props>(({ children, title = '', style, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title}`}</title>
    </Helmet>
    <Card size="small" ref={ref} style={style} {...other}>
      {children}
    </Card>
  </>
));
