import { callApi } from 'src/apis/connection/callApi';

export type SignInPayload = { profile: string; username: string; password: string };

export default {
  getGroupPermission(): Promise<any> {
    return callApi({ method: 'get', uri: '/iam-admin/api/role/get-list-role' });
  },
  getGroupCustom(id: number): Promise<any> {
    return callApi({ method: 'get', uri: `/iam-admin/api/role/get-list-role-custom/${id}` });
  },
  getGroupDefault(): Promise<any> {
    return callApi({ method: 'get', uri: '/iam-admin/api/role/get-list-role-default' });
  },
  getGroupPermissionDetail(body: any): Promise<any> {
    return callApi({ method: 'post', uri: '/iam-admin/api/role/get-detail-role', body });
  },
  saveGroupPermission(body: any): Promise<any> {
    return callApi({ method: 'post', uri: '/iam-admin/api/role/save-role', body });
  },
  deleteGroupPermission(body: any): Promise<any> {
    return callApi({ method: 'post', uri: '/iam-admin/api/role/remove-role', body });
  },
  getUsersPermission(payload?: string): Promise<any> {
    return callApi({ method: 'get', uri: `/iam-admin/api/account-cms/get-list-user/${payload}` });
  },
  getUserPermissionDetail(payload: any): Promise<any> {
    return callApi({ method: 'get', uri: `/iam-admin/api/account-cms/find/${payload}` });
  },
  saveUserPermission(body: any): Promise<any> {
    return callApi({ method: 'post', uri: `/iam-admin/api/account-cms/register`, body });
  },
  updateUserPermission(body: any): Promise<any> {
    return callApi({ method: 'post', uri: `/iam-admin/api/account-cms/update-user`, body });
  },
  updateSelfUser(body: any): Promise<any> {
    return callApi({ method: 'post', uri: `/iam-admin/api/account-cms/update-self-user`, body });
  },
  // deleteUserPermission(payload: any): Promise<any> {
  //   return callApi({ method: 'post', uri: `/iam-admin/api/account-cms/delete/${payload}` });
  // },
  getPermissions(): Promise<any> {
    return callApi({ method: 'get', uri: `/iam-admin/api/permission/list-permission-cms` });
  },
  getPermissionsUserByRoles(body: any): Promise<any> {
    return callApi({ method: 'post', uri: `/iam-admin/api/permission/get-permission-by-roles-cms`, body });
  },

  //permission list config
  getConfigModule(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/module/parent', query });
  },
  createConfigModule(body: any): Promise<any> {
    return callApi({ method: 'post', uri: '/iam/v1/module/create', body });
  },
  updateConfigModule(body: any): Promise<any> {
    return callApi({ method: 'put', uri: '/iam/v1/module/update', body });
  },
  deleteConfigModule(id: number): Promise<any> {
    return callApi({ method: 'delete', uri: `/iam/v1/module/${id}/delete` });
  },
  getDetailConfigModule(id: number): Promise<any> {
    return callApi({ method: 'get', uri: `/iam/v1/module/${id}/detail` });
  },
  getSubModuleByParentId(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/module/children', query });
  },
  getPerMissionSubModule(): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/permission/module' });
  },
  getModuleMenu(): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/module/menu' });
  },

  //new group permission
  getNewGroupPermission(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/group', query });
  },
  getDetailGroupPerMission(id: string): Promise<any> {
    return callApi({ method: 'get', uri: `/iam/v1/group/${id}/detail` });
  },
  createNewGroupPermission(body: any): Promise<any> {
    return callApi({ method: 'post', uri: '/iam/v1/group/create', body });
  },
  updateNewGroupPermission(body: any): Promise<any> {
    return callApi({ method: 'put', uri: '/iam/v1/group/update', body });
  },
  deleteNewGroupPermission(id: string): Promise<any> {
    return callApi({ method: 'delete', uri: `/iam/v1/group/${id}` });
  },
  getModuleOption(): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/module/option' });
  },

  // new account administrator
  getAccountAdminitrator(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/admin', query });
  },
  createAccountAdministrator(body: any): Promise<any> {
    return callApi({ method: 'post', uri: '/iam/v1/admin/create', body });
  },
  updateAccountAdministrator(body: any): Promise<any> {
    return callApi({ method: 'put', uri: '/iam/v1/admin/update', body });
  },
  detailAccountAdminitrator(id: string): Promise<any> {
    return callApi({ method: 'get', uri: `/iam/v1/admin/${id}/detail` });
  },

  //user
  updateUser(body: any): Promise<any> {
    return callApi({ method: 'put', uri: '/iam/v1/admin/update-me', body });
  },
};
