import { Icon } from '@iconify/react';
import { Button, Col, Form, Input, Row, Upload, UploadFile } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uploadFile from 'src/apis/service/uploadFile';
import Container from 'src/components/Container';
import { PATH } from 'src/configs/path';
import config from 'src/serviceConfig.json';
import { UPDATE_SUCCESS_MESSAGE } from 'src/constants/message';
import { useUpdateTeamInTourOfflineMutation } from 'src/graphql/mutations/updateTeamInTourOffline.graphql';
import {
  MemberTeamInTournamentOffline,
  TeamInTourOffline,
  UpdateTeamInTourOfflineDto,
} from 'src/graphql/type.interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';

type Props = { currentTeamInTour?: TeamInTourOffline };

export default ({ currentTeamInTour }: Props) => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [pathThumbnail, setPathThumbnail] = useState<string>('');
  const [arrayImage, setArrayImage] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (currentTeamInTour) {
      const { name, avatar } = currentTeamInTour;

      if (avatar) {
        setArrayImage([{ uid: '1', name: avatar, url: avatar, status: 'done' } as UploadFile]);
        setPathThumbnail(avatar);
      }
      form.setFieldsValue({
        name,
      });
    }
  }, [currentTeamInTour]);

  const [updateTeamInTourOfflineMutation] = useUpdateTeamInTourOfflineMutation({
    onCompleted: () => {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.tournamentOffline.detail(currentTeamInTour?.tourOffline as string));
    },
    onError({ message }) {
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const customRequestUpload = async (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();

    formData.append('file', file);
    const res = await uploadFile(formData);

    if (res?.success) {
      setPathThumbnail(res.data.preview);
      setArrayImage([
        {
          uid: '1',
          name: res.data.path,
          url: `${config.DOMAIN_CDN_IMAGE}/${res.data.path}`,
          status: 'done',
        } as UploadFile,
      ]);
    } else {
      enqueueSnackbar(res?.message, { variant: 'error' });
    }
  };

  const onFinish = async (form: UpdateTeamInTourOfflineDto) => {
    const { name } = form;

    if (currentTeamInTour) {
      updateTeamInTourOfflineMutation({
        variables: {
          input: {
            _id: currentTeamInTour._id as string,
            name,
            avatar: pathThumbnail,
            members: (currentTeamInTour.members as MemberTeamInTournamentOffline[]).map((item) => {
              return {
                name: item.name,
                uid: item.uid,
                role: item?.role as string,
              };
            }),
          },
        },
      });
    }
  };

  return (
    <>
      <Container title="Thông tin giải đấu">
        <Row>
          <Col offset={3} span={18}>
            <Form form={form} onFinish={onFinish}>
              <Form.Item
                label={
                  <div>
                    Ảnh thumbnail đội <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                required={false}
                labelCol={{ span: 24 }}
                name="avatar"
              >
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  multiple={false}
                  fileList={arrayImage}
                  customRequest={customRequestUpload}
                  onRemove={() => {
                    setArrayImage([]);
                    setPathThumbnail('');
                  }}
                >
                  <Icon icon="bi:upload" width="25" height="25" />
                </Upload>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                label={
                  <div>
                    Tên đội chơi <span style={{ color: 'red' }}> *</span>
                  </div>
                }
                name="name"
              >
                <Input />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
