import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateItemMutationVariables = Types.Exact<{
  input: Types.UpdateItemDto;
}>;

export type UpdateItemMutationResponse = { __typename?: 'Mutation' } & {
  updateItem: { __typename?: 'Item' } & Pick<
    Types.Item,
    | '_id'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'gradientCode'
    | 'isActivate'
    | 'isDeleted'
    | 'logo'
    | 'name'
    | 'point'
    | 'receiveMethod'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  >;
};

export const UpdateItemDocument = gql`
  mutation updateItem($input: UpdateItemDto!) {
    updateItem(input: $input) {
      _id
      createdAt
      createdBy
      description
      gradientCode
      isActivate
      isDeleted
      logo
      name
      point
      receiveMethod
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useUpdateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateItemMutationResponse, UpdateItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateItemMutationResponse, UpdateItemMutationVariables>(UpdateItemDocument, options);
}
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutationResponse>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemMutationResponse,
  UpdateItemMutationVariables
>;
