import { useState } from 'react';
import { Breadcrumb, Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import Container from 'src/components/Container';

import { useAchievementQuery } from 'src/graphql/queries/achievement.graphql';
import { AchievementCustom } from 'src/graphql/type.interface';
import dayjs from 'dayjs';
import { FORMAT_TIME_TO_MINUTES } from 'src/constants/common';
import { TYPE_ACHIEVEMENT_DISPLAY } from 'src/constants/constants';

export default () => {
  const navigate = useNavigate();
  const [currentAchievement, setCurrentAchievement] = useState<AchievementCustom>();
  const id = useParams()._id as string;

  useAchievementQuery({
    variables: { _id: id },
    onCompleted(data) {
      setCurrentAchievement(data.achievement);
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.achievement.list)}>
                Thành tựu
              </p>
            ),
          },
          { title: `Chi tiết: ${currentAchievement?.name}` },
        ]}
      />
      <Container title="Chi tiết thành tựu">
        {currentAchievement && (
          <div style={{ padding: '0 10%' }}>
            <div style={{ marginTop: '16px', display: 'flex' }}>
              <span style={{ width: '25%', paddingBottom: '20px' }}>Loại thành tựu </span>
              <span style={{ flex: 1 }}>
                {TYPE_ACHIEVEMENT_DISPLAY.find((item) => item.key === currentAchievement.type)?.value}
              </span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', paddingBottom: '20px' }}>Tên thành tựu </span>
              <span style={{ flex: 1 }}>{currentAchievement?.name}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', paddingBottom: '20px' }}>Mô tả </span>
              <span style={{ flex: 1 }}>{currentAchievement?.description}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', paddingBottom: '20px' }}>Số lần hoàn thành </span>
              <span style={{ flex: 1 }}>{currentAchievement?.completionQuantity?.quantity}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', paddingBottom: '20px' }}>Phần thưởng </span>
              <span style={{ flex: 1 }}>
                {currentAchievement?.reward?.value} {currentAchievement?.reward?.type}
              </span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ width: '25%', paddingBottom: '20px' }}>Ngày tạo </span>
              <span style={{ flex: 1 }}>{dayjs(currentAchievement?.createdAt).format(FORMAT_TIME_TO_MINUTES)}</span>
            </div>

            <></>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
              <Button type="primary" onClick={() => navigate(PATH.achievement.list)}>
                Quay lại
              </Button>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};
