import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type ItemQueryResponse = { __typename?: 'Query' } & {
  item: { __typename?: 'Item' } & Pick<
    Types.Item,
    | '_id'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'gradientCode'
    | 'isActivate'
    | 'isDeleted'
    | 'logo'
    | 'name'
    | 'point'
    | 'receiveMethod'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  >;
};

export const ItemDocument = gql`
  query item($id: String!) {
    item(id: $id) {
      _id
      createdAt
      createdBy
      description
      gradientCode
      isActivate
      isDeleted
      logo
      name
      point
      receiveMethod
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<ItemQueryResponse, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemQueryResponse, ItemQueryVariables>(ItemDocument, options);
}
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemQueryResponse, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemQueryResponse, ItemQueryVariables>(ItemDocument, options);
}
export function useItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ItemQueryResponse, ItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ItemQueryResponse, ItemQueryVariables>(ItemDocument, options);
}
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemSuspenseQueryHookResult = ReturnType<typeof useItemSuspenseQuery>;
export type ItemQueryResult = Apollo.QueryResult<ItemQueryResponse, ItemQueryVariables>;
