import Axios from 'axios';
import config from 'src/serviceConfig.json';
import { RESTErrorResponse } from 'src/types/api';

type Response = {
  data: { path: string; preview: string };
  message: string;
  success: boolean;
  code: number;
} & RESTErrorResponse;

const API = Axios.create({
  baseURL: `${config.API_GATEWAY}/media-helper/api/upload/image`,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

export default async (formData: FormData): Promise<Response | null> => {
  try {
    const { data } = await API.post<Response>('', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

    return data;
  } catch (err) {
    return err?.response?.data;
  }
};
