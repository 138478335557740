import { Button, Card, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import OtpInput from 'react-otp-input';
import { useDetailStore, useLoginStore } from 'src/stores';
import AuthRepository from '../../apis/service/administrator';
import { getUserId, setSession } from 'src/utils/jwt';
import { LOGIN_SUCCESS_MESSAGE } from 'src/constants/message';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ENV } from 'src/constants/common';

export default () => {
  const [form] = Form.useForm();
  const [otp, setOtp] = useState('');
  const { userInfo, setUserInfo } = useLoginStore();
  const navigate = useNavigate();
  const { setUserDetail } = useDetailStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendOTP = async () => {
    const res = await AuthRepository.getOTP({ type: 'PHONE', phone: (userInfo as any)?.phone });
    setOtp(res.data);
  };

  ENV !== 'production' &&
    useEffect(() => {
      if (otp) {
        form.setFieldsValue({ otp });
      }
    }, [otp]);

  const getUserModule = async () => {
    const { data, error } = await AuthRepository.getAdminModule();
    if (data) {
      setUserInfo(data);
    }
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const getUserDetail = async () => {
    const userId = getUserId();
    const { data, error } = await AuthRepository.getAdminDetail(userId);
    if (data) {
      setUserDetail(data);
    }
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const onFinish = async () => {
    setIsLoading(true);
    if (otp !== '') {
      const res = await AuthRepository.signIn({
        type: 'Normal',
        username: (userInfo as any).username,
        password: (userInfo as any).password,
        otpPhone: otp,
      });

      if (res.code === 200) {
        setSession(res.data.accessToken);
        navigate('/', { replace: true });
        message.success(LOGIN_SUCCESS_MESSAGE);
        setIsLoading(false);
        getUserModule();
        getUserDetail();
      } else {
        message.error(res.message);
        setIsLoading(false);
      }
    } else {
      handleSendOTP();
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Nhập OTP</title>
      </Helmet>
      <div style={{ width: 500, position: 'fixed', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <div style={{ marginBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img style={{ width: '50%' }} src="/image/logo.svg" alt="" />
        </div>
        <Card style={{ boxShadow: '0 4px 16px 0 rgba(167, 175, 183, 0.33)' }}>
          <Form
            form={form}
            initialValues={{ username: (userInfo as any).username, password: (userInfo as any).password }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item name="otp">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: '65px',
                  height: '65px',
                  margin: '0 5px',
                  fontSize: '18px',
                  textAlign: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  outline: 'none',
                }}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }} disabled={isLoading}>
                {otp !== '' ? 'Xác nhận' : 'Gửi OTP'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};
