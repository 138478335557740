import config from '../serviceConfig.json';

export const TINY_API_KEY = config.TINY_KEY;
export const GRAPHQL_ENDPOINT = '/graphql';
export const FORMAT_TIME = 'DD/MM/YYYY HH:mm:ss';
export const FORMAT_TIME_TO_MINUTES = 'DD/MM/YYYY HH:mm';
export const FORMAT_DATE = 'DD/MM/YYYY';
export const FORMAT_MONTH = 'YYYY-MM';
export const FORMAT_PARAM_DATE = 'YYYY-MM-DD';
export const FORMAT_CUSTOMER_DATE = 'YYYY-MM-DD HH:mm:ss';
export const ID_ADMIN = 1;

// PERMISSION ENDPOINT
export const PERMISSION_GET_ROLES_ENDPOINT = '/api/role/get-list-role';
export const PERMISSION_GET_ROLE_CUSTOM_ENDPOINT = '/api/role/get-list-role-custom';
export const PERMISSION_GET_ROLE_DEFAULT_ENDPOINT = '/api/role/get-list-role-default';
export const PERMISSION_GET_ROLE_DETAIL_ENDPOINT = '/api/role/get-detail-role';
export const PERMISSION_SAVE_ROLE_ENDPOINT = '/api/role/save-role';
export const PERMISSION_DELETE_ROLE_ENDPOINT = '/api/role/remove-role';
export const PERMISSION_GET_ACCOUNTS_CMS_ENDPOINT = '/api/account-cms/get-list-user';
export const PERMISSION_GET_ACCOUNT_CMS_DETAIL_ENDPOINT = '/api/account-cms/find/';
export const PERMISSION_CREATE_ACCOUNT_CMS_ENDPOINT = '/api/account-cms/register';
export const PERMISSION_UPDATE_ACCOUNT_CMS_ENDPOINT = '/api/account-cms/update-user';
export const PERMISSION_DELETE_ACCOUNT_CMS_ENDPOINT = '/api/account-cms/delete/';
export const PERMISSION_GET_MODULES_ENDPOINT = '/api/permission/list-permission-cms';
export const PERMISSION_GET_MODULES_BY_ROLES_ENDPOINT = '/api/permission/get-permission-by-roles-cms';

//SERVICE PACKAGE ENDPOINT
export const SERVICE_PACKAGE_GET_ALL_ENDPOINT = '/service-package/find-all-package-with-pageable';
export const SERVICE_PACKAGE_GET_DETAIL_ENDPOINT = '/service-package/find-by-id/';
export const SERVICE_PACKAGE_CREATE_ENDPOINT = '/service-package/add';
export const SERVICE_PACKAGE_UPDATE_ENDPOINT = '/service-package/update';
export const SERVICE_PACKAGE_CLONE_ENDPOINT = '/service-package/clone-service-package';
export const SERVICE_PACKAGE_DELETE_ENDPOINT = '/service-package/delete-service-package';

//SERVICE PROGRAM ENDPOINT
export const SERVICE_PROGRAM_GET_ALL_BY_PACKAGE_ID_ENDPOINT = '/service-program/find-by-package-id/';
export const SERVICE_PROGRAM_GET_DETAIL_ENDPOINT = '/service-program/find-by-id/';
export const SERVICE_PROGRAM_CREATE_ENDPOINT = '/service-program/add';
export const SERVICE_PROGRAM_UPDATE_ENDPOINT = '/service-program/update';
export const SERVICE_PROGRAM_CLONE_ENDPOINT = '/service-program/clone-service-program';
export const SERVICE_PROGRAM_DELETE_ENDPOINT = '/service-program/delete-service-program';

//ACCOUNT NORMAL ENDPOINT
export const ACCOUNT_NORMAL_GET_ALL_ENDPOINT = '/api/account-normal/get-list-user?pageSize=500';
export const ACCOUNT_NORMAL_GET_DETAIL_ENDPOINT = '/api/account-normal/find/';
export const ACCOUNT_NORMAL_CREATE_ENDPOINT = '/api/account-normal/create-user';
export const ACCOUNT_NORMAL_UPDATE_ENDPOINT = '/api/account-normal/update-user';
export const ACCOUNT_NORMAL_DELETE_ENDPOINT = '/api/account-normal/delete';
export const UPDATE_SELF_USER_ENDPOINT = '/api/account-cms/update-self-user';

// LOG
export const GET_LOG_LIST = '/api/logging/findAll';
export const GET_LOG_DETAIL = '/api/logging/find';
export const GET_LOG_REGISTER = '/api/v1/register';
export const GET_LOG_CHANGE = '/api/v1/package-log';
export const GET_LOG_REGISTER_DETAIL = '/api/v1/register';

// DATA PACKAGE

export const GET_PACKAGE_LIST = '/api/v1/package';
export const CREATE_PACKAGE = '/api/v1/package';
export const UPDATE_PACKAGE = '/api/v1/package';
export const DELETE_PACKAGE = 'api/v1/package';
export const DETAIL_PACKAGE = '/api/v1/package';

export const EVENT_REF_ID = 'EVENT_REF_ID';
export const ENV = config.ENV;
export const GROUP_ID_CTKV = 24;
