import { closeSnackbar } from 'notistack';

export default ({ snackbarKey }: any) => (
  <div onClick={() => closeSnackbar(snackbarKey)}>
    <img
      src="/image/close.svg"
      alt="close icon"
      style={{ width: '20px', height: '20px', color: 'while', marginRight: '8px' }}
    />
  </div>
);
