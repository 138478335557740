import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PromotionCampaignQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type PromotionCampaignQueryResponse = { __typename?: 'Query' } & {
  promotionCampaign: { __typename?: 'PromotionCampaign' } & Pick<
    Types.PromotionCampaign,
    | '_id'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'endTime'
    | 'isActive'
    | 'isDeleted'
    | 'isHotMobile'
    | 'logo'
    | 'name'
    | 'nameDispatch'
    | 'numberAwards'
    | 'startTime'
    | 'status'
    | 'updatedAt'
    | 'updatedBy'
    | 'url'
  > & {
      promotionStrategies: Array<
        { __typename?: 'PromotionStrategy' } & Pick<
          Types.PromotionStrategy,
          | '_id'
          | 'campaignId'
          | 'commandCode'
          | 'contentProviderId'
          | 'countConsecutiveRenewal'
          | 'createdAt'
          | 'createdBy'
          | 'isDeleted'
          | 'packageCode'
          | 'smsCode'
          | 'typeStrategy'
          | 'updatedAt'
          | 'updatedBy'
        > & {
            messageMT?: Types.Maybe<
              { __typename?: 'PromotionStrategyMessageMT' } & Pick<
                Types.PromotionStrategyMessageMt,
                'mtRegisterRenew' | 'mtReward'
              >
            >;
            reward?: Types.Maybe<
              { __typename?: 'PromotionStrategyReward' } & Pick<
                Types.PromotionStrategyReward,
                'addReward' | 'rewardTimes' | 'type' | 'value'
              >
            >;
          }
      >;
      rewardedTimeRange?: Types.Maybe<
        Array<{ __typename?: 'TimeRange' } & Pick<Types.TimeRange, 'endTime' | 'startTime'>>
      >;
    };
};

export const PromotionCampaignDocument = gql`
  query promotionCampaign($id: String!) {
    promotionCampaign(id: $id) {
      _id
      createdAt
      createdBy
      description
      endTime
      isActive
      isDeleted
      isHotMobile
      logo
      name
      nameDispatch
      numberAwards
      promotionStrategies {
        _id
        campaignId
        commandCode
        contentProviderId
        countConsecutiveRenewal
        createdAt
        createdBy
        isDeleted
        messageMT {
          mtRegisterRenew
          mtReward
        }
        packageCode
        reward {
          addReward
          rewardTimes
          type
          value
        }
        smsCode
        typeStrategy
        updatedAt
        updatedBy
      }
      rewardedTimeRange {
        endTime
        startTime
      }
      startTime
      status
      updatedAt
      updatedBy
      url
    }
  }
`;
export function usePromotionCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<PromotionCampaignQueryResponse, PromotionCampaignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromotionCampaignQueryResponse, PromotionCampaignQueryVariables>(
    PromotionCampaignDocument,
    options,
  );
}
export function usePromotionCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PromotionCampaignQueryResponse, PromotionCampaignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PromotionCampaignQueryResponse, PromotionCampaignQueryVariables>(
    PromotionCampaignDocument,
    options,
  );
}
export function usePromotionCampaignSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PromotionCampaignQueryResponse, PromotionCampaignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PromotionCampaignQueryResponse, PromotionCampaignQueryVariables>(
    PromotionCampaignDocument,
    options,
  );
}
export type PromotionCampaignQueryHookResult = ReturnType<typeof usePromotionCampaignQuery>;
export type PromotionCampaignLazyQueryHookResult = ReturnType<typeof usePromotionCampaignLazyQuery>;
export type PromotionCampaignSuspenseQueryHookResult = ReturnType<typeof usePromotionCampaignSuspenseQuery>;
export type PromotionCampaignQueryResult = Apollo.QueryResult<
  PromotionCampaignQueryResponse,
  PromotionCampaignQueryVariables
>;
