import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllContentProvidersQueryVariables = Types.Exact<{
  query: Types.QueryContentProviderG1Dto;
}>;

export type AllContentProvidersQueryResponse = { __typename?: 'Query' } & {
  allContentProviders: { __typename?: 'ContentProviderG1Paginate' } & {
    items: Array<
      { __typename?: 'ContentProviderG1' } & Pick<
        Types.ContentProviderG1,
        | '_id'
        | 'address'
        | 'adsText'
        | 'apiGateway'
        | 'apiKey'
        | 'avatar'
        | 'code'
        | 'contractCode'
        | 'contractEndDate'
        | 'contractStartDate'
        | 'createdAt'
        | 'createdBy'
        | 'customerSupport'
        | 'desc'
        | 'document'
        | 'gameType'
        | 'isActivate'
        | 'isDeleted'
        | 'isG1Only'
        | 'isValidGame'
        | 'logo'
        | 'manager'
        | 'name'
        | 'presentor'
        | 'presentorEmail'
        | 'sharedProfit'
        | 'shortName'
        | 'type'
        | 'updatedAt'
        | 'updatedBy'
        | 'url'
      > & { ftp?: Types.Maybe<{ __typename?: 'FTP' } & Pick<Types.Ftp, 'host' | 'password' | 'username'>> }
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AllContentProvidersDocument = gql`
  query allContentProviders($query: QueryContentProviderG1Dto!) {
    allContentProviders(query: $query) {
      items {
        _id
        address
        adsText
        apiGateway
        apiKey
        avatar
        code
        contractCode
        contractEndDate
        contractStartDate
        createdAt
        createdBy
        customerSupport
        desc
        document
        ftp {
          host
          password
          username
        }
        gameType
        isActivate
        isDeleted
        isG1Only
        isValidGame
        logo
        manager
        name
        presentor
        presentorEmail
        sharedProfit
        shortName
        type
        updatedAt
        updatedBy
        url
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAllContentProvidersQuery(
  baseOptions: Apollo.QueryHookOptions<AllContentProvidersQueryResponse, AllContentProvidersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllContentProvidersQueryResponse, AllContentProvidersQueryVariables>(
    AllContentProvidersDocument,
    options,
  );
}
export function useAllContentProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllContentProvidersQueryResponse, AllContentProvidersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllContentProvidersQueryResponse, AllContentProvidersQueryVariables>(
    AllContentProvidersDocument,
    options,
  );
}
export function useAllContentProvidersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AllContentProvidersQueryResponse, AllContentProvidersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllContentProvidersQueryResponse, AllContentProvidersQueryVariables>(
    AllContentProvidersDocument,
    options,
  );
}
export type AllContentProvidersQueryHookResult = ReturnType<typeof useAllContentProvidersQuery>;
export type AllContentProvidersLazyQueryHookResult = ReturnType<typeof useAllContentProvidersLazyQuery>;
export type AllContentProvidersSuspenseQueryHookResult = ReturnType<typeof useAllContentProvidersSuspenseQuery>;
export type AllContentProvidersQueryResult = Apollo.QueryResult<
  AllContentProvidersQueryResponse,
  AllContentProvidersQueryVariables
>;
