import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import viVN from 'antd/locale/vi_VN';
import { Suspense, lazy } from 'react';
import { ConfigProvider, Spin } from 'antd';
import timezone from 'dayjs/plugin/timezone';
import CacheBuster from 'react-cache-buster';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { client } from 'src/configs/apollo';
import SnackbarCloseButton from 'src/components/SnackbarCloseButton';
import pack from '../package.json';
import 'dayjs/locale/vi';
import 'antd/dist/reset.css';
import './styles.scss';
dayjs.locale('vi');
dayjs.extend(utc);
dayjs.extend(timezone);

const isProduction = process.env.NODE_ENV === 'production';
const MainLayout = lazy(() => import('./layouts/MainLayout'));

export default () => (
  <CacheBuster currentVersion={pack.version} isEnabled={isProduction}>
    <ApolloProvider client={client}>
      <QueryClientProvider client={new QueryClient()}>
        <ConfigProvider locale={viVN}>
          <Suspense fallback={<Spin size="large" className="globa_spin" />}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              autoHideDuration={5000}
              action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            >
              <MainLayout />
            </SnackbarProvider>
          </Suspense>
        </ConfigProvider>
      </QueryClientProvider>
    </ApolloProvider>
  </CacheBuster>
);
