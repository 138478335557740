import { callApi } from 'src/apis/connection/callApi';

export default {
  getPromotionArtifactCodeExcel(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: `/cms/promotion-artifact-code/excel`,
      query,
      responseType: 'blob',
    });
  },
  importFiles(body: any): Promise<any> {
    return callApi({
      method: 'post',
      uri: `/cms/promotion-content-provider-reward/import-excel`,
      body,
    });
  },
  getPromotionEligible(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/promotion/isdn-eligible',
      query,
    });
  },
  exportExcelPromotionEligible(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/excel/isdn-eligible',
      query,
      responseType: 'blob',
    });
  },
  getEffectivenessPromotionReport(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/promotion/effectiveness-evaluation',
      query,
    });
  },
  exportExcelTemplate(): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/cms/upload/promotion-content-provider-reward/template',
      responseType: 'blob',
    });
  },
};
