import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilestonesQueryVariables = Types.Exact<{
  query: Types.MilestonePaginateDto;
}>;

export type MilestonesQueryResponse = { __typename?: 'Query' } & {
  milestones: { __typename?: 'MilestonePaginate' } & {
    items: Array<
      { __typename?: 'Milestone' } & Pick<
        Types.Milestone,
        '_id' | 'achievementIds' | 'createdAt' | 'createdBy' | 'level' | 'name' | 'updatedAt' | 'updatedBy'
      > & {
          achievements?: Types.Maybe<
            Array<
              { __typename?: 'Achievement' } & Pick<
                Types.Achievement,
                | '_id'
                | 'createdAt'
                | 'createdBy'
                | 'description'
                | 'isDeleted'
                | 'name'
                | 'performs'
                | 'type'
                | 'updatedAt'
                | 'updatedBy'
              > & {
                  completionQuantity?: Types.Maybe<
                    { __typename?: 'AchievementCompletionQuantity' } & Pick<
                      Types.AchievementCompletionQuantity,
                      'quantity' | 'type'
                    >
                  >;
                  reward: { __typename?: 'AchievementReward' } & Pick<Types.AchievementReward, 'type' | 'value'>;
                }
            >
          >;
          reward: Array<
            { __typename?: 'MilestoneReward' } & Pick<Types.MilestoneReward, 'type' | 'value'> & {
                item?: Types.Maybe<
                  { __typename?: 'Item' } & Pick<
                    Types.Item,
                    | '_id'
                    | 'createdAt'
                    | 'createdBy'
                    | 'description'
                    | 'gradientCode'
                    | 'isActivate'
                    | 'isDeleted'
                    | 'logo'
                    | 'name'
                    | 'point'
                    | 'receiveMethod'
                    | 'type'
                    | 'updatedAt'
                    | 'updatedBy'
                  >
                >;
              }
          >;
        }
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const MilestonesDocument = gql`
  query milestones($query: MilestonePaginateDto!) {
    milestones(query: $query) {
      items {
        _id
        achievementIds
        achievements {
          _id
          completionQuantity {
            quantity
            type
          }
          createdAt
          createdBy
          description
          isDeleted
          name
          performs
          reward {
            type
            value
          }
          type
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        level
        name
        reward {
          item {
            _id
            createdAt
            createdBy
            description
            gradientCode
            isActivate
            isDeleted
            logo
            name
            point
            receiveMethod
            type
            updatedAt
            updatedBy
          }
          type
          value
        }
        updatedAt
        updatedBy
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useMilestonesQuery(
  baseOptions: Apollo.QueryHookOptions<MilestonesQueryResponse, MilestonesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MilestonesQueryResponse, MilestonesQueryVariables>(MilestonesDocument, options);
}
export function useMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MilestonesQueryResponse, MilestonesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MilestonesQueryResponse, MilestonesQueryVariables>(MilestonesDocument, options);
}
export function useMilestonesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MilestonesQueryResponse, MilestonesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MilestonesQueryResponse, MilestonesQueryVariables>(MilestonesDocument, options);
}
export type MilestonesQueryHookResult = ReturnType<typeof useMilestonesQuery>;
export type MilestonesLazyQueryHookResult = ReturnType<typeof useMilestonesLazyQuery>;
export type MilestonesSuspenseQueryHookResult = ReturnType<typeof useMilestonesSuspenseQuery>;
export type MilestonesQueryResult = Apollo.QueryResult<MilestonesQueryResponse, MilestonesQueryVariables>;
