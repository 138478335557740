import { PaymentBillingChannelEnum } from 'src/enums/payment.enum';

export const paymentTransactionStatus = {
  PENDING: { text: 'PENDING', color: 'warning' },
  SUCCESS: { text: 'SUCCESS', color: 'success' },
  FAIL: { text: 'FAIL', color: 'error' },
};

export const getBillingChannelName = (channel: string) => {
  switch (channel) {
    case PaymentBillingChannelEnum.APPLE_PAY:
      return 'Apple Pay';
    case PaymentBillingChannelEnum.GOOGLE_PAY:
      return 'Google Pay';
    case PaymentBillingChannelEnum.WALLET:
      return 'Mobifone Money';
    case PaymentBillingChannelEnum.BANK:
      return 'ATM nội địa';
    case PaymentBillingChannelEnum.INTERNATIONAL_CARD:
      return 'Visa';
    default:
      return '';
  }
};
