import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';

import { PATH } from 'src/configs/path';
import Container from 'src/components/Container';
import CommandSpecialForm from 'src/sections/package/CommandSpecialForm';

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.package.package.list)}>
                Gói cước
              </p>
            ),
          },
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.package.specialCodeCP.list)}>
                Cú pháp đặc biệt
              </p>
            ),
          },
          { title: 'Thêm mới' },
        ]}
      />
      <Container title="Cú pháp đặc biệt">
        <CommandSpecialForm />
      </Container>
    </>
  );
};
