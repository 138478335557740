import {
  Package,
  ReportPackage,
  ReportStatisticByDayPackageCombo,
  ReportStatisticByDayPackageContent,
  ReportStatisticByDaySumRevenue,
  ReportStatisticByMonthPackageCombo,
  ReportStatisticByMonthPackageContent,
  ReportStatisticByMonthSumRevenue,
} from 'src/types/package';
import { RestResponse } from 'src/types/api';
import { Register } from 'src/types/register';
import { callApi } from 'src/apis/connection/callApi';
import { ReportTypeEnum } from 'src/enums/package.enum';

export type LogPackage = {
  id: number;
  packageCode: string;
  action: string;
  byUsername: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: number | null;
  createdBy: number | null;
  updatedBy: number | null;
};

export type PackageQuery = {
  limit: number;
  page: number;
  keyword?: string;
  action?: string;
  startTime?: string;
  endTime?: string;
  type?: string;
  packageType?: string;
  status?: string;
  packageCode?: string;
  contentProviderId?: string;
  isValidSubCommand?: boolean;
  paymentMethod?: string;
  subCommand?: string;
  phoneNumber?: string;
};

export type ConfigPackageQuery = {
  limit: number;
  page: number;
  keyword?: string;
};

export type ConfigPackagePayload = {
  data: {
    key: string;
    value: string;
  }[];
};

export type ReportPackageQuery = {
  limit: number;
  page: number;
  keyword?: string;
  type?: string;
  startDay?: string;
  endDay?: string;
  contentProviderId?: string;
};

export type ReportSubCommandQuery = {
  limit: number;
  page: number;
  keyword?: string;
  startDay?: string;
  endDay?: string;
  userId?: number;
};

export type ReportPackageExcelQuery = {
  type?: ReportTypeEnum;
  startDay?: string;
  endDay?: string;
  contentProviderId?: string;
};

export type ReportSubCommandExcelQuery = {
  startDay?: string;
  endDay?: string;
  userId?: number;
};

export type ReportStatisticQuery = {
  type?: ReportTypeEnum;
  startDay?: string;
  endDay?: string;
  contentProviderId?: string;
};

export type ReportStatisticByMonthQuery = {
  type?: ReportTypeEnum;
  month?: string;
  contentProviderId?: string;
};

export type ReportCPStatistic = {
  startDay?: string;
  endDay?: string;
  contentProviderId?: string;
};

export type ReportCPByMonth = {
  month: string;
  contentProviderId?: string;
};

export type ConfigPackage = {
  key: string;
  value: string;
};

export type ValidSubCommandPackage = {
  limit: number;
  page: number;
  keyword?: string;
  userId?: string;
  packageIds?: string;
  subCommand?: string;
};

export type UserLoginMethod = {
  startDay?: string;
  endDay?: string;
  contentProviderId?: string;
};

export type CreateSpecialCodeCP = {
  command?: string;
  description?: string;
  contentProviderId?: string;
};

type RegisterRespon = {
  data: { items: Register[]; paging: { currentPage: number; totalItems: number } };
} & RestResponse;

type ChangeRespon = {
  data: { items: LogPackage[]; paging: { currentPage: number; totalItems: number } };
} & RestResponse;

type DetailRespon = {
  data: Register;
} & RestResponse;

type GetPackages = {
  data: {
    items: Package[];
    paging: { currentPage: number; itemCount: number; itemsPerPage: number; totalItems: number; totalPages: number };
  };
} & RestResponse;

export type ConfigPackageRespon = {
  key: string;
  value: string;
} & RestResponse;

type GetReportsPackages = {
  items: ReportPackage[];
  paging: { currentPage: number; itemCount: number; itemsPerPage: number; totalItems: number; totalPages: number };
  sumAllRevenueDay: number;
  sumCurrentYearRevenueDay: number;
} & RestResponse;

type GetConfigPackages = {
  data: {
    items: ConfigPackageRespon[];
    paging: { currentPage: number; itemCount: number; itemsPerPage: number; totalItems: number; totalPages: number };
  };
} & RestResponse;

type GetReportStatisticByDay = {
  items: [ReportStatisticByDayPackageContent, ReportStatisticByDayPackageCombo, ReportStatisticByDaySumRevenue];
} & RestResponse;

type GetReportStatisticByMonth = {
  items: [ReportStatisticByMonthPackageContent, ReportStatisticByMonthPackageCombo, ReportStatisticByMonthSumRevenue];
} & RestResponse;

type CreatePackage = { data: Package } & RestResponse;

type UpdatePackage = { data: Package } & RestResponse;

type GetByIdPackage = { data: Package } & RestResponse;

type DeletePackage = { data: Package } & RestResponse;

export default {
  getRegisters(query: PackageQuery): Promise<RegisterRespon> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/register', query });
  },
  getRegisterById(id: number): Promise<DetailRespon> {
    return callApi({ method: 'get', uri: `/package-management/api/v1/register/${id}` });
  },
  getLogPackages(query: PackageQuery): Promise<ChangeRespon> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/package-log', query });
  },
  getPackages(query: PackageQuery): Promise<GetPackages> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/package', query });
  },
  createPackage(body: Package): Promise<CreatePackage> {
    return callApi({ method: 'post', uri: '/package-management/api/v1/package', body });
  },
  updatePackage(body: Package): Promise<UpdatePackage> {
    return callApi({ method: 'put', uri: '/package-management/api/v1/package', body });
  },
  getPackageById(id: number): Promise<GetByIdPackage> {
    return callApi({ method: 'get', uri: `/package-management/api/v1/package/${id}` });
  },
  getPackageHistoryById(id: number): Promise<GetByIdPackage> {
    return callApi({ method: 'get', uri: `/package-management/api/v1/register/register-file/${id}` });
  },
  deletePackage(id: number): Promise<DeletePackage> {
    return callApi({ method: 'delete', uri: `/package-management/api/v1/package/${id}` });
  },
  configPackage(query: ConfigPackageQuery): Promise<GetConfigPackages> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/config', query });
  },
  configPackageByKey(key: string): Promise<ConfigPackageRespon> {
    return callApi({ method: 'get', uri: `/package-management/api/v1/config/${key}` });
  },
  updateConfigPackage(body: ConfigPackagePayload): Promise<ConfigPackageRespon> {
    return callApi({ method: 'put', uri: `/package-management/api/v1/config`, body });
  },
  getReportPackage(query: ReportPackageQuery): Promise<GetReportsPackages> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report', query });
  },
  getReportRevenueSubCommand(query: ReportSubCommandQuery): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report/sub-command', query });
  },
  getReportStatisticByDay(query: ReportStatisticQuery): Promise<GetReportStatisticByDay> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report/statistic-day', query });
  },

  getReportStatisticByMonth(query: ReportStatisticByMonthQuery): Promise<GetReportStatisticByMonth> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report/statistic-month', query });
  },
  getReportPackageExcel(query: ReportPackageExcelQuery): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report/excel',
      query,
      responseType: 'blob',
    });
  },
  getReportSubCommandExcel(query: ReportSubCommandExcelQuery): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report/excel/sub-command',
      query,
      responseType: 'blob',
    });
  },
  getAllSubCommandPackage(query: ValidSubCommandPackage): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/sub-command-package', query });
  },
  getValidSubCommandPackage(query: ValidSubCommandPackage): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/sub-command-package/valid', query });
  },
  getReportStatisticV2ByDay(query: ReportStatisticQuery): Promise<GetReportStatisticByDay> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-v2/statistic-day', query });
  },
  getReportStatisticV2ByMonth(query: ReportStatisticByMonthQuery): Promise<GetReportStatisticByMonth> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-v2/statistic-month', query });
  },
  getReportPackageByDay(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-v2/package-day', query });
  },
  getReportPackageByMonth(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-v2/package-month', query });
  },
  getReportUserLoginMethod(query: UserLoginMethod): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-v2/user-login', query });
  },
  getReportSubcriber(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-test/subscriber', query });
  },
  getReportSubCancel(): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-v2/sub-cancel' });
  },
  getReportExcelStatisticByDay(query: ReportStatisticQuery): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/excel/statistic-day',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelStatisticByMouth(query: ReportStatisticByMonthQuery): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/excel/statistic-month',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelPackageByDay(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/excel/package-day',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelPackageByMouth(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/excel/package-month',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelSubCancel(): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/excel/sub-cancel',
      responseType: 'blob',
    });
  },
  getReportExcelUserLoginMethod(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-v2/excel/user-login',
      query,
      responseType: 'blob',
    });
  },
  getPackageHistory(query: PackageQuery): Promise<RegisterRespon> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/register/register-file', query });
  },
  getReportExcelPackageHistory(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/register/excel/register-file',
      query,
      responseType: 'blob',
    });
  },
  getTransactionHistory(body: { limit: number; page: number }): Promise<any> {
    return callApi({ method: 'post', uri: '/cms/payment/history', body });
  },
  getReportCPStatistic(query: ReportCPStatistic): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/statistic', query });
  },
  getReportCPRevenueByDay(query: ReportCPStatistic): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/revenue-day', query });
  },
  getReportCPRevenueByMounth(query: ReportCPByMonth): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/revenue-month', query });
  },
  getReportSubcription(query: ReportCPStatistic): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/subscriber', query });
  },
  getReportCommandCode(query: ReportCPStatistic): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/cmd-code', query });
  },
  getSpecialCodeCP(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/command-special', query });
  },
  createSpecialCodeCP(body: CreateSpecialCodeCP): Promise<any> {
    return callApi({ method: 'post', uri: '/package-management/api/v1/command-special', body });
  },
  updateSpecialCodeCP(body: any): Promise<any> {
    return callApi({ method: 'put', uri: '/package-management/api/v1/command-special', body });
  },
  getSpecialCodeById(id: number): Promise<any> {
    return callApi({ method: 'get', uri: `/package-management/api/v1/command-special/${id}` });
  },
  deleteCommandCode(id: number): Promise<any> {
    return callApi({ method: 'delete', uri: `/package-management/api/v1/command-special/${id}` });
  },
  getReportExcelCPStatic(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-cp/excel/statistic',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelCPRevenueByDay(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-cp/excel/revenue-day',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelCPRevenueByMonth(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-cp/excel/revenue-month',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelCPSubciption(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-cp/excel/subscriber',
      query,
      responseType: 'blob',
    });
  },
  getReportExcelCommandCode(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/package-management/api/v1/report-cp/excel/cmd-code',
      query,
      responseType: 'blob',
    });
  },
  getInforSubcription(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/history-info', query });
  },
  getInforAttendPromotion(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/history-join-promotion', query });
  },
  getHistoryLog(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/v1/report-cp/history-transaction', query });
  },
  getRecordOfReconciliation(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/statement', query });
  },
  exportExcelRecordOfReconciliation(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/package-management/api/statement/excel', query, responseType: 'blob' });
  },
};
