import { Button, Popconfirm } from 'antd';
import { ModulePermission, Permission, SubModule } from 'src/types/user';
import { useEffect, useState } from 'react';

import { PermissionType } from 'src/enums/permissionEnum';
import { commentStatusColor } from 'src/constants/commentStatus';
import useLoginStore from 'src/stores/login';
import { useNavigate } from 'react-router-dom';

type TypeProps = {
  action: number;
  moduleName: string;
  subModuleName: string;
  recordId?: string;
  status?: number;
  type?: string;
  onDeleteClick?: () => void;
  onApproveClick?: (status: any) => void;
  width?: any;
  name?: string;
  typeCP?: string;
  disabled?: boolean;
};

const BtnPermission = ({
  action,
  moduleName,
  subModuleName,
  recordId,
  status,
  type,
  onDeleteClick,
  onApproveClick,
  width,
  name,
  typeCP,
}: TypeProps) => {
  const [isDisable, setIsDisable] = useState(true);
  const [isDisableRejectBtn, setIsDisableRejectBtn] = useState(true);
  const [typeSubModule, setTypeSubModule] = useState('');

  const navigate = useNavigate();
  const { userInfo } = useLoginStore();
  const permissions = (userInfo ?? [])
    .find((module: ModulePermission) => module.name === moduleName)
    ?.subModules.find((subModules: SubModule) => subModules.name === subModuleName)
    ?.permissions?.map((per: Permission) => per.type);

  useEffect(() => {
    type ? setTypeSubModule(type) : setTypeSubModule('');
  }, [type]);

  useEffect(() => {
    if (permissions) {
      if (permissions.includes(Number(action))) {
        setIsDisable(false);
      }
      if (permissions.includes(PermissionType.APPROVE)) {
        setIsDisableRejectBtn(false);
      }
    } else {
      setIsDisable(true);
      setIsDisableRejectBtn(true);
    }
  }, [action]);

  const currentColor = commentStatusColor[status ? status : 1];
  const color = currentColor?.color;
  const text = currentColor?.text;

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick();
    }
  };

  const handleApproveClick = (status: number) => {
    if (onApproveClick) {
      onApproveClick(status);
    }
  };

  switch (action) {
    case PermissionType.READ:
      return (
        <Button
          disabled={isDisable}
          type="primary"
          style={{ float: 'right', width: '92px' }}
          onClick={() =>
            JSON.stringify(moduleName) === JSON.stringify(subModuleName)
              ? navigate(`/${moduleName}/detail`)
              : navigate(`/${moduleName}/${subModuleName}/detail/${recordId}`)
          }
        >
          Xem
        </Button>
      );
    case PermissionType.CREATE:
      return (
        <Button
          disabled={isDisable}
          type="primary"
          style={{ float: 'right' }}
          onClick={() =>
            JSON.stringify(moduleName) === JSON.stringify(subModuleName)
              ? navigate(`/${moduleName}/create`)
              : typeSubModule
              ? navigate(`/${moduleName}/${subModuleName}/create/${typeSubModule}`)
              : navigate(`/${moduleName}/${subModuleName}/create`)
          }
        >
          Thêm mới
        </Button>
      );
    case PermissionType.UPDATE:
      return (
        <Button
          style={{ width: '100%' }}
          disabled={isDisable}
          type="primary"
          onClick={() =>
            JSON.stringify(moduleName) === JSON.stringify(subModuleName)
              ? typeCP
                ? navigate(`/${moduleName}/edit/${typeCP}/${recordId}`)
                : navigate(`/${moduleName}/edit/${recordId}`)
              : typeCP
              ? navigate(`/${moduleName}/${subModuleName}/edit/${typeCP}/${recordId}`)
              : navigate(`/${moduleName}/${subModuleName}/edit/${recordId}`)
          }
        >
          {name ?? 'Sửa'}
        </Button>
      );
    case PermissionType.APPROVE:
      return (
        <>
          {isDisable && (
            <div>
              <Button
                style={{ width: '92px' }}
                color={color}
                onClick={() => handleApproveClick(status ? status + 1 : 1)}
                disabled={isDisable}
              >
                {text}
              </Button>
            </div>
          )}
        </>
      );
    case PermissionType.REJECT:
      return (
        <div>
          <Popconfirm
            title="Từ chối"
            description="Bạn chắc chắn từ chối không?"
            onConfirm={() => handleApproveClick(4)}
            cancelText="Hủy"
            okText="Đồng ý"
            placement="left"
            disabled={isDisableRejectBtn}
          >
            <Button
              type="primary"
              danger
              disabled={isDisableRejectBtn}
              style={{
                width: '92px',
              }}
            >
              Từ chối
            </Button>
          </Popconfirm>
        </div>
      );
    default:
      return (
        <Popconfirm
          title="Xóa"
          description="Bạn đồng ý xóa không?"
          onConfirm={handleDeleteClick}
          cancelText="Hủy"
          okText="Đồng ý"
          placement="left"
          disabled={isDisable}
        >
          <Button style={{ width: width || '92px' }} disabled={isDisable} type="primary" danger>
            Xoá
          </Button>
        </Popconfirm>
      );
  }
};

export default BtnPermission;
