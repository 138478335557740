import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PromotionContentProviderRewardsQueryVariables = Types.Exact<{
  query: Types.QueryPromotionContentProviderRewardDto;
}>;

export type PromotionContentProviderRewardsQueryResponse = { __typename?: 'Query' } & {
  promotionContentProviderRewards: { __typename?: 'PromotionContentProviderRewardPaginate' } & {
    items: Array<
      { __typename?: 'PromotionContentProviderReward' } & Pick<
        Types.PromotionContentProviderReward,
        | '_id'
        | 'campaignId'
        | 'contentProviderId'
        | 'cpName'
        | 'createdAt'
        | 'createdBy'
        | 'isdn'
        | 'mtMessage'
        | 'packageCode'
        | 'promotionName'
        | 'rewardType'
        | 'rewardValue'
        | 'serial'
        | 'status'
        | 'strategyId'
        | 'updatedAt'
        | 'updatedBy'
      > & {
          rewardedTimeRange?: Types.Maybe<
            { __typename?: 'TimeRange' } & Pick<Types.TimeRange, 'endTime' | 'startTime'>
          >;
        }
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const PromotionContentProviderRewardsDocument = gql`
  query promotionContentProviderRewards($query: QueryPromotionContentProviderRewardDto!) {
    promotionContentProviderRewards(query: $query) {
      items {
        _id
        campaignId
        contentProviderId
        cpName
        createdAt
        createdBy
        isdn
        mtMessage
        packageCode
        promotionName
        rewardType
        rewardValue
        rewardedTimeRange {
          endTime
          startTime
        }
        serial
        status
        strategyId
        updatedAt
        updatedBy
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function usePromotionContentProviderRewardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PromotionContentProviderRewardsQueryResponse,
    PromotionContentProviderRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromotionContentProviderRewardsQueryResponse, PromotionContentProviderRewardsQueryVariables>(
    PromotionContentProviderRewardsDocument,
    options,
  );
}
export function usePromotionContentProviderRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PromotionContentProviderRewardsQueryResponse,
    PromotionContentProviderRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PromotionContentProviderRewardsQueryResponse,
    PromotionContentProviderRewardsQueryVariables
  >(PromotionContentProviderRewardsDocument, options);
}
export function usePromotionContentProviderRewardsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PromotionContentProviderRewardsQueryResponse,
    PromotionContentProviderRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PromotionContentProviderRewardsQueryResponse,
    PromotionContentProviderRewardsQueryVariables
  >(PromotionContentProviderRewardsDocument, options);
}
export type PromotionContentProviderRewardsQueryHookResult = ReturnType<typeof usePromotionContentProviderRewardsQuery>;
export type PromotionContentProviderRewardsLazyQueryHookResult = ReturnType<
  typeof usePromotionContentProviderRewardsLazyQuery
>;
export type PromotionContentProviderRewardsSuspenseQueryHookResult = ReturnType<
  typeof usePromotionContentProviderRewardsSuspenseQuery
>;
export type PromotionContentProviderRewardsQueryResult = Apollo.QueryResult<
  PromotionContentProviderRewardsQueryResponse,
  PromotionContentProviderRewardsQueryVariables
>;
