import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PromotionCampaignsQueryVariables = Types.Exact<{
  query: Types.QueryPromotionCampaignDto;
}>;

export type PromotionCampaignsQueryResponse = { __typename?: 'Query' } & {
  promotionCampaigns: { __typename?: 'PromotionCampaignPaginate' } & {
    items: Array<
      { __typename?: 'PromotionCampaign' } & Pick<
        Types.PromotionCampaign,
        | '_id'
        | 'createdAt'
        | 'createdBy'
        | 'description'
        | 'endTime'
        | 'isActive'
        | 'isDeleted'
        | 'isHotMobile'
        | 'logo'
        | 'name'
        | 'nameDispatch'
        | 'numberAwards'
        | 'startTime'
        | 'status'
        | 'updatedAt'
        | 'updatedBy'
        | 'url'
      > & {
          promotionStrategies: Array<
            { __typename?: 'PromotionStrategy' } & Pick<
              Types.PromotionStrategy,
              | '_id'
              | 'campaignId'
              | 'commandCode'
              | 'contentProviderId'
              | 'countConsecutiveRenewal'
              | 'createdAt'
              | 'createdBy'
              | 'isDeleted'
              | 'packageCode'
              | 'smsCode'
              | 'typeStrategy'
              | 'updatedAt'
              | 'updatedBy'
            > & {
                messageMT?: Types.Maybe<
                  { __typename?: 'PromotionStrategyMessageMT' } & Pick<
                    Types.PromotionStrategyMessageMt,
                    'mtRegisterRenew' | 'mtReward'
                  >
                >;
                reward?: Types.Maybe<
                  { __typename?: 'PromotionStrategyReward' } & Pick<
                    Types.PromotionStrategyReward,
                    'addReward' | 'rewardTimes' | 'type' | 'value'
                  >
                >;
              }
          >;
          rewardedTimeRange?: Types.Maybe<
            Array<{ __typename?: 'TimeRange' } & Pick<Types.TimeRange, 'endTime' | 'startTime'>>
          >;
        }
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const PromotionCampaignsDocument = gql`
  query promotionCampaigns($query: QueryPromotionCampaignDto!) {
    promotionCampaigns(query: $query) {
      items {
        _id
        createdAt
        createdBy
        description
        endTime
        isActive
        isDeleted
        isHotMobile
        logo
        name
        nameDispatch
        numberAwards
        promotionStrategies {
          _id
          campaignId
          commandCode
          contentProviderId
          countConsecutiveRenewal
          createdAt
          createdBy
          isDeleted
          messageMT {
            mtRegisterRenew
            mtReward
          }
          packageCode
          reward {
            addReward
            rewardTimes
            type
            value
          }
          smsCode
          typeStrategy
          updatedAt
          updatedBy
        }
        rewardedTimeRange {
          endTime
          startTime
        }
        startTime
        status
        updatedAt
        updatedBy
        url
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function usePromotionCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<PromotionCampaignsQueryResponse, PromotionCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromotionCampaignsQueryResponse, PromotionCampaignsQueryVariables>(
    PromotionCampaignsDocument,
    options,
  );
}
export function usePromotionCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PromotionCampaignsQueryResponse, PromotionCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PromotionCampaignsQueryResponse, PromotionCampaignsQueryVariables>(
    PromotionCampaignsDocument,
    options,
  );
}
export function usePromotionCampaignsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PromotionCampaignsQueryResponse, PromotionCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PromotionCampaignsQueryResponse, PromotionCampaignsQueryVariables>(
    PromotionCampaignsDocument,
    options,
  );
}
export type PromotionCampaignsQueryHookResult = ReturnType<typeof usePromotionCampaignsQuery>;
export type PromotionCampaignsLazyQueryHookResult = ReturnType<typeof usePromotionCampaignsLazyQuery>;
export type PromotionCampaignsSuspenseQueryHookResult = ReturnType<typeof usePromotionCampaignsSuspenseQuery>;
export type PromotionCampaignsQueryResult = Apollo.QueryResult<
  PromotionCampaignsQueryResponse,
  PromotionCampaignsQueryVariables
>;
