import { callApi } from 'src/apis/connection/callApi';

export enum TypeReceived {
  MILESTONE = 'MILESTONE',
  ACHIEVEMENT = 'ACHIEVEMENT',
}

export enum TypeReward {
  ENERGY = 'ENERGY',
  GPOINT = 'GPOINT',
  ITEM = 'ITEM',
}

export type HistoryReceivedPayload = {
  limit: number;
  page: number;
  type: TypeReceived;
  rewardType?: TypeReward;
  keyword?: string;
  startTime?: string;
  endTime?: string;
  level?: number;
};

export default {
  getHistoryReceived(query: HistoryReceivedPayload): Promise<any> {
    return callApi({ method: 'get', uri: '/mobile/achievement/history-claim-reward', query });
  },
};
